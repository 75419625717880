import { Colors } from '../../constants';

export const JobOfferStatusesColors = {
  1: Colors.grey,
  2: Colors.darkGreen,
  3: Colors.darkYellow,
  4: Colors.blue,
  5: Colors.red,
};

export const JobOfferPriorityColors = {
  1: Colors.darkBlue,
  2: Colors.darkGreen,
  3: Colors.darkOrange,
  4: Colors.darkRed,
  5: Colors.lightGray,
};
