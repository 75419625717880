import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row, Tooltip } from 'antd';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { ParameterInput } from './ParameterInput';
import { SelectForEnum } from './SelectForEnum';
import { LanguageLevel } from '../Candidates/types';
import { ParameterType } from './types';
import './css/LanguageFormInputs.css';

interface LanguageFormInputsProps {
  name?: string;
  tenant?: string;
  hideLabels?: boolean;
  allowAdd?: boolean;
}

export const LanguageFormInputs: React.FC<LanguageFormInputsProps> = ({
  name,
  tenant,
  hideLabels,
  allowAdd = true,
}) => {
  const { t: translate } = useTranslation();

  return (
    <Form.List name={name || 'languages'}>
      {(fields, { add, remove }) => {
        return (
          <div id='LanguageFormInputs'>
            {fields.map((field, index) => (
              <>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginBottom: '5px' }} key={field.key}>
                  <Col span={10}>
                    <Form.Item
                      name={[field.name, 'languageId']}
                      label={index === 0 && !hideLabels ? translate('languages') : ''}
                    >
                      <ParameterInput
                        placeholder={translate('language')}
                        parameters={ParameterType.languages}
                        tenant={tenant}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      name={[field.name, 'level']}
                      label={index === 0 && !hideLabels ? translate('level') : ''}
                    >
                      <SelectForEnum placeholder={`${translate('level')}`} enumType={LanguageLevel} />
                    </Form.Item>
                  </Col>
                  <Col xs={4} sm={4} md={4} lg={4} style={{ display: 'flex', alignItems: 'end' }}>
                    <Form.Item>
                      <>
                        <Tooltip title={`${translate('delete')} ${translate('language')}`} placement='top'>
                          <DeleteOutlined
                            className='delete-button'
                            onClick={() => {
                              if (fields.length > 1) {
                                remove(field.name);
                              } else {
                                remove(field.name);
                                add();
                              }
                            }}
                          />
                        </Tooltip>
                      </>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={32} align='middle' justify='end'>
                  <Col>
                    {fields.length - 1 === index && allowAdd && (
                      <p
                        className='add-language-button'
                        onClick={() => {
                          add();
                        }}
                      >
                        <PlusCircleOutlined size={20} /> {translate('addLanguage')}
                      </p>
                    )}
                  </Col>
                </Row>
              </>
            ))}
          </div>
        );
      }}
    </Form.List>
  );
};

export default LanguageFormInputs;
