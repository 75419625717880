import React, { useState } from 'react';
import { Modal, Checkbox, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '../../hooks/auth/Auth0Provider';
import { Roles } from '../../hooks/auth/types';
import { useUsersAPIClient } from '../../hooks/api';
import { useTalentNetworkAPIClient } from '../../hooks/api/TalentNetworkAPIClient';

const TermsOfServicesModal: React.FC = () => {
  const [isModalConfirm, setIsModalConfirm] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const { t } = useTranslation();
  const { userIsInRole } = useAuth0();

  const usersAPIClient = useUsersAPIClient();
  const candidatesPrivateProfileAPIClient = useTalentNetworkAPIClient();

  const { logout } = useAuth0();

  const acceptTermsOfService = async (): Promise<void> => {
    let res;

    if (userIsInRole(Roles.talentNetworkCandidate)) {
      res = await candidatesPrivateProfileAPIClient.acceptTermsOfService();
    } else {
      res = await usersAPIClient.acceptTermsOfService();
    }

    if (res?.hasError) {
      message.error(`Ups! ${res.errorMessage}!`);
      return;
    }

    window.location.reload();
  };

  const handleCancel = (): void => {
    return logout();
  };

  return (
    <div>
      <Modal
        title={t('termsOfService')}
        open={true}
        onOk={acceptTermsOfService}
        okButtonProps={{ disabled: !isChecked }}
        onCancel={() => setIsModalConfirm(true)}
      >
        <p style={{ display: 'inline', margin: 0 }}>{`${t('termsOfServiceModalParagraph1')} `}</p>
        <a href='/terms-of-service' target='_blank' style={{ display: 'inline' }}>
          {t('termsOfService')}
        </a>
        <p style={{ display: 'inline' }}>{`. ${t('termsOfServiceModalParagraph2')}`}</p>
        <Checkbox style={{ display: 'flex' }} onChange={e => setIsChecked(e.target.checked)}>{` ${t(
          'iAcceptTheTermsOfService',
        )}`}</Checkbox>
      </Modal>

      <Modal title={t('confirm')} open={isModalConfirm} onOk={handleCancel} onCancel={() => setIsModalConfirm(false)}>
        <p>{t('areYouSureYouWantLogginOutTOS')}</p>
      </Modal>
    </div>
  );
};

export default TermsOfServicesModal;
