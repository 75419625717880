import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, message, Popover, Row, Tooltip } from 'antd';
import { DeleteOutlined, DownCircleOutlined, InfoCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form';
import { APIRequestResult, useCandidatesAPIClient, useParametersAPIClient } from '../../hooks/api';
import { ParameterInput } from './ParameterInput';
import { SelectForEnum } from './SelectForEnum';
import { TechLogoSize, TechnologyLevel, TechnologyInterest, ParameterType } from './types';
import {
  useArraySessionStorage,
  useDateFormatter,
  useErrorMessageHandler,
  useSharedContext,
  useViewport,
} from '../../hooks';
import { SessionStorageKeys } from '../../constants';
import { Parameter } from '../Parameters/types';
import TechnologiesLogo from './TechnologiesLogo';
import { ExperienceYearsInput } from '.';
import { PrimaryButton } from './IconButton';
import { CandidateTechnology } from '../Candidates/types';
import dayjs from 'dayjs';
import { JobOfferTechnology } from '../JobOffers/types';

import './css/TechnologiesFormInputs.css';

interface TechnologiesFormInputsProps {
  currentTechnologies?: Array<CandidateTechnology | JobOfferTechnology>;
  candidateId?: number;
  name?: string;
  form: FormInstance<any>;
  hideMostUsedTech?: boolean;
  tenant?: string;
  isFilter?: boolean;
  isTechnologyValidable?: boolean;
  orSupport?: boolean;
}

export const TechnologiesFormInputs: React.FC<TechnologiesFormInputsProps> = ({
  name,
  form,
  hideMostUsedTech,
  tenant,
  isFilter,
  currentTechnologies,
  candidateId,
  isTechnologyValidable,
  orSupport,
}) => {
  const { t: translate } = useTranslation();
  const { isTablet } = useViewport();
  const { toLocalDateTime } = useDateFormatter();
  const { showOverlay, hideOverlay } = useSharedContext();
  const { errorMessageHandler } = useErrorMessageHandler();
  const { setFormHasChanges } = useSharedContext();
  const candidatesAPIClient = useCandidatesAPIClient();
  const parametersAPIClient = useParametersAPIClient();

  const getParametersInputList = (): Promise<APIRequestResult<Array<Parameter>>> =>
    tenant
      ? parametersAPIClient.listForTenant(SessionStorageKeys.TECHNOLOGIES, tenant)
      : parametersAPIClient.list(SessionStorageKeys.TECHNOLOGIES);

  const [technologies, technologiesLoaded] = useArraySessionStorage<Parameter>(
    getParametersInputList,
    SessionStorageKeys.TECHNOLOGIES,
  );

  const [mostUsedTechs, __] = hideMostUsedTech
    ? [[], true]
    : useArraySessionStorage<Parameter>(candidatesAPIClient.getMostUsedTechs, SessionStorageKeys.MOST_USED_TECHS);

  const lastLevelRef = useRef<HTMLSelectElement>(null);

  const yoe = (
    <div>
      {translate('yoe')}{' '}
      <Tooltip title={translate('yearsOfExperience')}>
        <InfoCircleOutlined />
      </Tooltip>
    </div>
  );
  const [disabledValidateButton, setDisabledValidateButton] = useState({});

  useEffect(() => {
    const technologiesValidation = {};
    const formTechs =
      form.getFieldValue('technologies').length > 1
        ? form.getFieldValue('technologies').filter(form => form.technologyId)
        : form.getFieldValue('technologies');
    if (currentTechnologies) {
      formTechs.map((tech, index) => {
        const techWithId = formTechs[index];
        techWithId.id = currentTechnologies[index]?.id;
        techWithId.description = currentTechnologies[index]?.description;
        formTechs[index] = techWithId;
        form.setFieldValue('technologies', formTechs);
      });
      currentTechnologies?.map(tech => {
        technologiesValidation[tech.id] = false;
      });
    }
    setDisabledValidateButton(technologiesValidation);
  }, [currentTechnologies]);

  const TopTechs = add => {
    if (hideMostUsedTech) return null;

    return (
      <div id='TechnologiesFormInputs'>
        <div className='topTechs'>
          {mostUsedTechs.map((tech, i) => (
            <TechnologiesLogo
              key={i}
              techName={tech.descriptionEn}
              logoSize={isTablet() ? TechLogoSize.small : TechLogoSize.medium}
              onClick={() => {
                const technologies = form.getFieldValue(['technologies']);

                if (technologies.length !== 1 || technologies[0].technologyId) {
                  add({
                    technologyId: tech.id,
                  });
                } else {
                  technologies[0].technologyId = tech.id;
                  setFormHasChanges(true);

                  form.setFieldsValue({
                    technologies,
                  });
                }

                setTimeout(() => {
                  lastLevelRef && lastLevelRef.current && lastLevelRef.current.focus();
                }, 100);
              }}
            />
          ))}
        </div>
      </div>
    );
  };

  const mergeRefs = (...refs) => {
    const filteredRefs = refs.filter(Boolean);
    if (!filteredRefs.length) return null;
    if (filteredRefs.length === 0) return filteredRefs[0];
    return inst => {
      for (const ref of filteredRefs) {
        if (typeof ref === 'function') {
          ref(inst);
        } else if (ref) {
          ref.current = inst;
        }
      }
    };
  };

  const onInputChange = (fieldName: Array<string | number>, index, value) => {
    const id = form.getFieldValue('technologies')[index].id;
    setDisabledValidateButton({ ...disabledValidateButton, [id]: true });
    form.setFieldValue(fieldName, value);
    const tech = form.getFieldValue('technologies');
    tech[index] = { ...tech[index], validationDate: null };
    form.setFieldValue('technologies', tech);
  };

  const getTechName = (index: number) => {
    const tech = technologies.find(tech => {
      return tech.id === form.getFieldValue('technologies')[index].technologyId;
    });
    return tech ? tech.description : null;
  };

  const onValidateTechnology = async (index: number): Promise<void> => {
    showOverlay();
    const { technologyId } = form.getFieldValue('technologies')[index];
    if (candidateId) {
      const res = await candidatesAPIClient.validateTechnology(candidateId, technologyId);

      if (res.hasError || !res.data) {
        errorMessageHandler(res);
        hideOverlay();
        return;
      }
      if (res.data) {
        const techs = form.getFieldValue('technologies');
        techs[index] = res.data;
        form.setFieldValue('technologies', techs);
        message.success(`${translate('technologyValidated')}!`);
      }
      hideOverlay();
    }
  };

  const onUndoTechnologyValidation = async (index: number): Promise<void> => {
    showOverlay();
    const { technologyId } = form.getFieldValue('technologies')[index];
    if (candidateId) {
      const res = await candidatesAPIClient.unvalidateTechnology(candidateId, technologyId);

      if (res.hasError || !res.data) {
        errorMessageHandler(res);
        hideOverlay();
        return;
      }

      if (res.data) {
        const techs = form.getFieldValue('technologies');
        techs[index] = res.data;
        form.setFieldValue('technologies', techs);
        message.success(`${translate('validationDeleted')}!`);
      }
      hideOverlay();
    }
  };

  const showOrLabel = (index: number): boolean => {
    if (!orSupport) return false;

    const techs = form.getFieldValue('technologies');

    if (!techs[index]?.orTechnologyId) return false;

    if (techs[index]?.subOrTechnology) return true;

    return false;
  };

  const showAddOrTechnologyButton = (index: number): boolean => {
    if (!orSupport) return false;

    const techs = form.getFieldValue('technologies');

    if (!techs[index]?.technologyId) return false;

    if (!techs[index]?.orTechnologyId) return true;

    if (techs[index]?.baseOrTechnology) return true;

    return false;
  };

  const showAddTechnologyButton = (index: number, fields: Array<any>): boolean => {
    if (!orSupport) return fields.length - 1 === index;

    const techs = form.getFieldValue('technologies');

    if (!techs[index]?.orTechnologyId) {
      return index == techs.length - 1;
    }

    if (techs.slice(index + 1).filter(x => x?.orTechnologyId !== techs[index]?.orTechnologyId).length !== 0)
      return false;

    if (techs[index]?.baseOrTechnology) return true;

    return false;
  };

  const orTechnologyBaseInputIsDisabled = (index: number): boolean => {
    const techs = form.getFieldValue('technologies');
    if (techs[index]?.description || techs[index]?.id) return true;

    return false;
  };

  if (!technologiesLoaded) return <></>;

  return (
    <div id='TechnologiesFormInputs'>
      <Form.List name={name || 'technologies'}>
        {(fields, { remove, add }) => {
          return (
            <div>
              <div>
                {fields.map((field, index) => {
                  const levelRef = React.createRef<HTMLSelectElement>();
                  const experienceYearsRef = React.createRef<HTMLInputElement>();

                  return (
                    <>
                      <Row gutter={8} key={field.key} style={{ marginBottom: '5px' }}>
                        {showOrLabel(index) && (
                          <Col span={24} style={{ marginTop: -23, marginLeft: 8 }}>
                            {translate('or')}
                          </Col>
                        )}
                        <Col xs={7} sm={isFilter && fields.length > 1 ? 7 : !isFilter ? 3 : 7}>
                          <Form.Item
                            name={[field.name, 'technologyId']}
                            label={index === 0 ? translate('technologies') : ''}
                          >
                            {orTechnologyBaseInputIsDisabled(index) ? (
                              <p className='techName'>{getTechName(index)}</p>
                            ) : (
                              <ParameterInput
                                onChange={e => {
                                  onInputChange([field.name, 'technologyId'], index, e);
                                }}
                                placeholder={translate('technologies')}
                                parameters={ParameterType.technologies}
                                tenant={tenant}
                                onSelect={() => levelRef && levelRef.current && levelRef.current.focus()}
                              />
                            )}
                          </Form.Item>

                          {orSupport && <Form.Item name={[field.name, 'orTechnologyId']} hidden />}
                        </Col>
                        <Col
                          xs={7}
                          sm={isFilter && fields.length > 1 ? 7 : !isFilter ? 4 : 7}
                          className={isFilter ? 'levelCol' : ''}
                        >
                          <Form.Item name={[field.name, 'level']} label={index === 0 ? translate('level') : ''}>
                            <SelectForEnum
                              placeholder={`${translate('level')}`}
                              enumType={TechnologyLevel}
                              onChange={e => {
                                onInputChange([field.name, 'level'], index, e);
                              }}
                              ref={
                                fields.length - 1 === index && mostUsedTechs
                                  ? mergeRefs(lastLevelRef, levelRef)
                                  : levelRef
                              }
                              onSelect={() =>
                                experienceYearsRef && experienceYearsRef.current && experienceYearsRef.current.focus()
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={7}
                          sm={isFilter && fields.length > 1 ? 7 : !isFilter ? 5 : 7}
                          className={isFilter ? 'experienceYearsCol' : ''}
                        >
                          <Form.Item name={[field.name, 'experienceYears']} label={index === 0 ? yoe : ''}>
                            <ExperienceYearsInput
                              onChange={e => {
                                onInputChange([field.name, 'experienceYears'], index, e);
                              }}
                            />
                          </Form.Item>
                        </Col>
                        {!isFilter && (
                          <>
                            {isTechnologyValidable && (
                              <Col xs={24} sm={8} md={8} lg={3}>
                                <Form.Item
                                  name={[field.name, 'interest']}
                                  label={index === 0 ? translate('interest') : ''}
                                >
                                  <SelectForEnum
                                    placeholder={`${translate('interest')}`}
                                    onChange={e => {
                                      onInputChange([field.name, 'interest'], index, e);
                                    }}
                                    enumType={TechnologyInterest}
                                  />
                                </Form.Item>
                              </Col>
                            )}
                            {isTechnologyValidable && (
                              <Col
                                xs={20}
                                sm={14}
                                md={5}
                                lg={4}
                                className={index === 0 ? 'validationColumn' : undefined}
                              >
                                {form.getFieldValue('technologies')[index].validationDate ? (
                                  <div
                                    style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: '15px' }}
                                  >
                                    <PrimaryButton
                                      className={
                                        !disabledValidateButton[form.getFieldValue('technologies')[index].id]
                                          ? 'validatedButton'
                                          : 'validateButton'
                                      }
                                      disabled={disabledValidateButton[form.getFieldValue('technologies')[index].id]}
                                    >
                                      {translate('validated')}
                                    </PrimaryButton>
                                    <Popover
                                      content={
                                        <div style={{ color: '#828282', maxWidth: '30rem' }}>
                                          <p style={{ marginBottom: 0 }}>
                                            <span style={{ fontWeight: 'bold' }}>{translate('validatedBy')}: </span>
                                            {`${
                                              form.getFieldValue('technologies')[index].validationUserName
                                            } - ${toLocalDateTime(
                                              form.getFieldValue('technologies')[index].validationDate,
                                            )}`}
                                          </p>
                                          <p style={{ marginBottom: 10 }}>
                                            {`${translate('since')} ${dayjs().diff(
                                              dayjs(
                                                form.getFieldValue('technologies')[index].validationDate,
                                                'YYYY-MM-DD',
                                              ),
                                              'days',
                                            )} ${translate('days')}`}
                                          </p>
                                          <ul style={{ paddingInlineStart: '20px', margin: 0 }}>
                                            {form.getFieldValue('technologies')[index].description && (
                                              <li>{form.getFieldValue('technologies')[index].description}</li>
                                            )}
                                            {form.getFieldValue('technologies')[index].levelName && (
                                              <li>{form.getFieldValue('technologies')[index].levelName}</li>
                                            )}
                                            {form.getFieldValue('technologies')[index].experienceYears && (
                                              <li>{`${
                                                form.getFieldValue('technologies')[index].experienceYears
                                              } ${translate('yearsOfExperience')}`}</li>
                                            )}
                                            {form.getFieldValue('technologies')[index].interestName && (
                                              <li>{form.getFieldValue('technologies')[index].interestName}</li>
                                            )}
                                          </ul>
                                          <p
                                            style={{ textAlign: 'end', color: '#28B6E3', cursor: 'pointer', margin: 0 }}
                                            onClick={() => onUndoTechnologyValidation(index)}
                                          >
                                            {translate('removeValidation')}
                                          </p>
                                        </div>
                                      }
                                    >
                                      <InfoCircleOutlined style={{ fontSize: '20px' }} />
                                    </Popover>
                                  </div>
                                ) : (
                                  <Button
                                    className='secondaryButton'
                                    disabled={
                                      disabledValidateButton[form.getFieldValue('technologies')[index].id] === false
                                        ? false
                                        : true
                                    }
                                    onClick={() => onValidateTechnology && onValidateTechnology(index)}
                                  >
                                    {translate('validate')}
                                  </Button>
                                )}
                              </Col>
                            )}
                          </>
                        )}
                        <Col xs={3} sm={2} md={3} lg={1} style={{ display: 'flex', alignItems: 'end' }}>
                          <Form.Item>
                            <Tooltip title={`${translate('delete')} ${translate('technology')}`} placement='top'>
                              <DeleteOutlined
                                className='delete-button'
                                onClick={() => {
                                  if (!orSupport) {
                                    remove(field.name);
                                    fields.length === 1 && add({ technologyId: null, description: null });
                                    return;
                                  }

                                  //for or options
                                  const techRemoved = form.getFieldValue('technologies')[field.name];

                                  remove(field.name);
                                  if (techRemoved.technologyId === techRemoved.orTechnologyId) {
                                    const techs = form.getFieldValue('technologies').map((e, i) => {
                                      if (e?.orTechnologyId === techRemoved?.orTechnologyId) {
                                        return {
                                          ...e,
                                          orTechnologyId: null,
                                          baseOrTechnology: null,
                                          subOrTechnology: null,
                                        };
                                      } else {
                                        return e;
                                      }
                                    });
                                    form.setFieldValue('technologies', techs);
                                    return;
                                  }

                                  //Removing another sub option
                                  const orOptions = form
                                    .getFieldValue('technologies')
                                    .filter(e => e.orTechnologyId === techRemoved.orTechnologyId);

                                  //If the suboption was the last one, clear the orTechnologyId from the first one of the pack
                                  if (orOptions.length === 1) {
                                    const currentTechs = form.getFieldValue('technologies');

                                    currentTechs.map((e, i) => {
                                      if (e.orTechnologyId === techRemoved.orTechnologyId) {
                                        e.orTechnologyId = null;
                                        e.baseOrTechnology = false;
                                      }
                                    });

                                    form.setFieldValue('technologies', currentTechs);
                                  }

                                  fields.length === 1 && add({ technologyId: null, description: null });
                                }}
                              />
                            </Tooltip>

                            {showAddOrTechnologyButton(index) && (
                              <Tooltip
                                title={`${translate('add')} '${translate('or')}' ${translate('technology')}`}
                                placement='top'
                              >
                                <DownCircleOutlined
                                  className='dynamic-add-button'
                                  onClick={() => {
                                    if (!orSupport) {
                                      add({
                                        technologyId: null,
                                        description: null,
                                      });
                                      return;
                                    }

                                    const techs = form.getFieldValue('technologies');

                                    techs[index].orTechnologyId = techs[index].technologyId;
                                    techs[index].baseOrTechnology = true;

                                    form.setFieldValue('technologies', techs);
                                    add(
                                      {
                                        technologyId: null,
                                        description: null,
                                        orTechnologyId: form.getFieldValue('technologies')[index].technologyId,
                                        subOrTechnology: true,
                                      },
                                      index + 1,
                                    );
                                  }}
                                />
                              </Tooltip>
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                      {fields.length - 1 === index && (
                        <Row gutter={32} align='middle' justify='end'>
                          <Col>
                            {showAddTechnologyButton(index, fields) && (
                              <p
                                className='add-skill-button'
                                onClick={() => {
                                  add({
                                    technologyId: null,
                                    description: null,
                                  });
                                }}
                              >
                                <PlusCircleOutlined size={20} /> {translate('addSkill')}
                              </p>
                            )}
                          </Col>
                        </Row>
                      )}
                    </>
                  );
                })}
              </div>
              {TopTechs(add)}
            </div>
          );
        }}
      </Form.List>
    </div>
  );
};

export default TechnologiesFormInputs;
