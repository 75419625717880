import React, { useEffect, useState } from 'react';
import { Avatar, Table } from 'antd';
import { DividerMarkersColor } from '../Common/DividerMarkersColor';
import { useJobOffersAPIClient } from '../../hooks/api';
import { JobOfferListItem, Status } from '../JobOffers/types';
import { JobOfferStatusesColors } from '../JobOffers/constants';
import { isEmpty } from 'lodash';
import { ShopOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

type JobOffersListFormItemProps = {
  companyIds: Array<number>;
  value?: Array<number>;
  excludedIds?: Array<number>;
  onChange?: (jobOfferIds: Array<number>) => void;
  showOverlay?: () => void;
  hideOverlay?: () => void;
};

export const JobOffersListFormItem: React.FC<JobOffersListFormItemProps> = ({
  companyIds,
  value,
  excludedIds,
  onChange,
  showOverlay,
  hideOverlay,
}) => {
  const [jobOffers, setJobOffers] = useState<Array<JobOfferListItem>>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<Array<number>>(value ? value : []);

  const { t } = useTranslation();

  const jobOffersAPIClient = useJobOffersAPIClient();

  useEffect(() => {
    const getJobOffersList = async (): Promise<void> => {
      if (!companyIds) return;

      showOverlay && showOverlay();

      const page = 0;
      const size = 50;
      const advancedSearchFilters = { status: Status.active, companyIds: companyIds };

      const res = await jobOffersAPIClient.search(page, size, advancedSearchFilters);

      if (res.data) {
        const jobOffersId = res.data.map(jobOffer => jobOffer.id);
        const selectedRowsItems = jobOffersId
          .concat(selectedRowKeys)
          .filter((item, index) => jobOffersId.concat(selectedRowKeys).indexOf(item) !== index);
        setSelectedRowKeys(selectedRowsItems);
        value && onChange?.(selectedRowsItems);
        if (!excludedIds || isEmpty(excludedIds)) {
          setJobOffers(res.data);
        } else {
          const ids = new Set(excludedIds);
          setJobOffers(res.data.filter(d => !ids.has(d.id)));
        }
      }
      hideOverlay && hideOverlay();
    };

    getJobOffersList();
  }, [companyIds, excludedIds]);

  const columns = [
    {
      render: (text, jobOffer) => {
        return <Avatar size={37} src={jobOffer.companyLogo} icon={<ShopOutlined />} className='avatar' />;
      },
    },
    {
      title: t('company'),
      key: 'title',
      render: (text, jobOffer) => {
        return <span>{jobOffer.companyName}</span>;
      },
    },
    {
      title: t('jobOffer'),
      render: (text, jobOffer) => {
        return <span>{jobOffer.title}</span>;
      },
    },
    {
      title: t('status'),
      render: (text, jobOffer) => {
        return <DividerMarkersColor color={JobOfferStatusesColors[jobOffer.status]} text={jobOffer.statusName} />;
      },
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: selectedRowKeys => {
      setSelectedRowKeys(selectedRowKeys);
      onChange?.(selectedRowKeys);
    },
  };

  return (
    <div id='JobOffersListFormItemProps'>
      <Table
        rowKey={record => record.id}
        rowSelection={rowSelection}
        className='jobOffer-table'
        dataSource={jobOffers}
        columns={columns}
        pagination={false}
      />
    </div>
  );
};

export default JobOffersListFormItem;
