import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, message } from 'antd';
import dayjs from 'dayjs';
import { Interview } from '../types';
import { InformationPanel } from './InformationPanel';
import { useInterviewsAPIClient } from '../../../hooks/api';
import { useErrorMessageHandler } from '../../../hooks';
import { scrollToFirstErrorDefaults } from '../../../constants/DefaultTheme';

import './css/Add.css';

type AddProps = {
  candidateId?: number;
  onInterviewAdded: (newInterview: Interview) => void;
  showOverlay?: () => void;
  hideOverlay?: () => void;
};

export const Add: React.FC<AddProps> = ({ candidateId, onInterviewAdded, showOverlay, hideOverlay }) => {
  const { t } = useTranslation();
  const { errorMessageHandler } = useErrorMessageHandler();
  const [form] = Form.useForm();

  const interviewsAPIClient = useInterviewsAPIClient();

  const onSubmit = async (interviewFormData): Promise<void> => {
    showOverlay && showOverlay();

    if (candidateId) {
      interviewFormData.candidateId = candidateId;
    }

    const createInterviewResponse = await interviewsAPIClient.create(interviewFormData);

    if (createInterviewResponse.hasError) {
      errorMessageHandler(createInterviewResponse);
      hideOverlay && hideOverlay();
      return;
    }

    message.success(`${t('interviewAdded')}!`);
    form.resetFields();

    if (onInterviewAdded && createInterviewResponse.data) {
      onInterviewAdded(createInterviewResponse.data);
    }

    hideOverlay && hideOverlay();
  };

  return (
    <div id='Interviews' className='AddInterview'>
      <Form
        form={form}
        layout='vertical'
        autoComplete='off'
        scrollToFirstError={scrollToFirstErrorDefaults}
        initialValues={{
          status: 1,
          type: 1,
          source: 1,
          scheduledAt: dayjs().add(1, 'hour'),
        }}
        onFinish={onSubmit}
      >
        <InformationPanel candidateId={candidateId} />
      </Form>
    </div>
  );
};

export default React.memo(Add);
