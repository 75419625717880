import React from 'react';
import hexToRgb from '../../utils/helper/parseHexToRgb';
import './css/DividerMarkersColor.css';

interface DividerMarkersProps {
  text: string;
  color: string;
  fontSize?: number;
  className?: string;
  backgroundColor?: string;
}

export const DividerMarkersColor: React.FC<DividerMarkersProps> = ({
  text,
  color,
  className,
  backgroundColor,
  fontSize,
}) => {
  const rgbBackgroundColor = hexToRgb(color);
  return (
    <div className={className ?? 'DividerMarkersColor'}>
      <p
        className='container'
        style={{ color: color, backgroundColor: backgroundColor || rgbBackgroundColor, fontSize: fontSize }}
      >
        {text}
      </p>
    </div>
  );
};
