import { Dayjs } from 'dayjs';

export enum ReferrerType {
  candidates = 'candidates',
  prospectCompanies = 'prospect-companies',
  prospectClients = 'prospect-clients',
  jobOffers = 'job-offers',
  jobApplications = 'job-applications',
  companies = 'companies',
  groups = 'groups',
  scheduledTasks = 'scheduled-tasks',
  dashboard = 'dashboard',
  parameters = 'parameters',
  interviews = 'interviews',
}

export enum ParameterType {
  scheduledTaskTypes = 'scheduled-task-types',
  technologies = 'technologies',
  excludedCompanies = 'excluded-companies',
  subStatuses = 'sub-statuses',
  flags = 'flags',
  industries = 'industries',
  departments = 'departments',
  languages = 'languages',
  prospectFlags = 'prospect-flags',
  roles = 'roles',
  questions = 'questions',
  stages = 'candidate-stages',
}

export enum ViewMode {
  simple = 'simple',
  full = 'full',
}

export type KeyValuePair<T = string> = {
  key: number;
  value: T;
};

export enum BrowserLanguages {
  ES = 'ES',
  EN = 'EN',
}

export enum Currency {
  USD = 'USD',
  ARS = 'ARS',
  COP = 'COP',
  CAD = 'CAD',
}

export enum Environment {
  development = 'Development',
  staging = 'Staging',
  production = 'Production',
}

export type NewAssignation = {
  assignedUserId: string;
  reason?: string;
  notes?: string;
  removePreviousAssignations?: boolean;
  giveOwnership?: boolean;
};

export type RequestAssignation = {
  reason?: string;
  notes?: string;
};

export type Language = {
  id: number;
  languageId: number;
  description: string;
  level: number;
  levelName: string;
};

export type Unassign = {
  assignedUserId: string;
};

export type AssignationData = {
  id: number;
  title: string;
};

export type Assignation = {
  id: number;
  assigned: string;
  assignedId: number;
  assignedPicture?: string;
  createdAt: string;
  assignedUserId: string;
  assignedUserName?: string;
  reason?: string;
  notes?: string;
  createdByUserId?: string;
  createdByUserName?: string;
  updatedByUserId?: string;
  updatedByUserName?: string;
  status: AssignationStatus;
  statusName?: string;
};

export type AssignationHistory = {
  assignedId: number;
  createdAt: string;
  assignedUserId: string;
  assignedUserName: string;
  createdByUserId: string;
  createdByUserName: string;
  isUnassign: boolean;
};

export enum AssignationStatus {
  requested = 1,
  approved,
  rejected,
  direct,
  group,
}

export type EntityStatusHistory = {
  id: number;
  parentId: number;
  parentDescription: string;
  status: number;
  statusName: string;
  notes: string;
  createdAt: string;
  createdByUserId: string;
  createdByUserName: string;
};

export type EntityVersionHistory = {
  id: number;
  userName: string;
  date: string;
  entityId: string;
};

export type PeriodFilter = {
  from: Dayjs;
  to: Dayjs;
};

export enum PeriodTypes {
  thisWeek = 'thisWeek',
  thisMonth = 'thisMonth',
  custom = 'custom',
}

export enum ViewStyles {
  cards = 'cards',
  list = 'list',
}

export enum ActivityType {
  note = 1,
  interview,
  event,
  contact,
  comment,
  message,
}

export enum ActivityVisibility {
  public = 1,
  management,
  private,
}

export enum TechnologyLevel {
  trainee = 1,
  junior,
  semiSenior,
  semiSeniorAdvanced,
  senior,
  seniorAdvanced,
  advanced,
}

export enum TechnologyInterest {
  notInterested = 1,
  interested,
  veryInterested,
}

export enum Difficulty {
  easy = 1,
  medium,
  difficult,
}

export enum Seniority {
  firstJob = 1,
  trainee,
  junior,
  semiSenior,
  semiSeniorAdvanced,
  senior,
  seniorAdvanced,
  advanced,
}

export enum WorkMethodology {
  remote = 1,
  onSite,
}

export enum SalaryType {
  hourly = 1,
  monthly,
  yearly,
}

export enum ContractType {
  fullTime = 1,
  partTime,
}

export enum PriorityLevel {
  low = 1,
  normal,
  high,
  urgent,
  paused,
}

export enum ContractTerm {
  shortTerm = 1,
  longTerm,
}

export enum TechLogoSize {
  large = 'large',
  medium = 'medium',
  small = 'small',
}

export type User = {
  id: string;
  email: string;
  fullName: string;
};

export type Country = {
  name: string;
  code: string;
};

export type Industry = {
  description?: string;
  industryId: number;
  experienceYears?: number;
};

export type Region = {
  name: string;
  countryCode: string;
};

export enum Mode {
  read = 1,
  edit,
}

export type City = {
  name: string;
  countryCode: string;
  regionName: string;
};

export type Timezone = {
  name: string;
  code: string;
  countryCode: string;
};

export type Address = {
  homeAddress?: string;
  country?: string;
  state?: string;
  city?: string;
  countryName?: string;
  zip?: string;
  timeZone?: string;
};

export type TableSort = {
  sortBy: string;
  sortDirection: TableSortDirection;
};

export enum TableSortDirection {
  asc,
  desc,
  noOrder,
}

export type OwnerHistory = {
  id: number;
  parentId: number;
  parentDescription?: string;
  ownedByUserId?: string;
  ownedByUserName?: string;
  createdAt: string;
  createdByUserId?: string;
  createdByUserName?: string;
};

export type AssignationOwner = {
  id: number;
  title: string;
};

export type Email = {
  id: number;
  referrerType: string;
  referrerId: string;
  subject: string;
  body: string;
  to: Array<string>;
  createdAt: string;
  updatedAt: string;
  createdByUserId: string;
  createdByUserName: string;
  updatedByUserId: string;
  updatedByUserName: string;
  metadata: string;
};

export enum PlanType {
  'Enterprise' = 1,
  'Platinum',
}
