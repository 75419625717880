import React from 'react';
import { SearchInput, SearchInputProps, SearchInputData } from './index';
import { useParametersAPIClient, APIRequestResult } from '../../hooks/api';
import { useArraySessionStorage } from '../../hooks';
import { Parameter } from '../Parameters/types';
import { useTypeGuards } from '../../utils/TypeGuards';
import { ParameterType } from './types';

interface ParameterInputProps extends Omit<SearchInputProps, 'fetch'> {
  parameters: string;
  disabled?: boolean;
  tenant?: string;
  filterPropertyName?: string;
  filterValue?: string | number;
  readOnly?: boolean;
  selectedOptions?: Array<number | string | undefined>;
  lng?: 'es' | 'en';
}

export const ParameterInput = React.forwardRef<HTMLSelectElement, ParameterInputProps>(
  (
    {
      parameters = ParameterType.flags,
      tenant,
      filterPropertyName,
      filterValue,
      selectedOptions,
      onChange,
      value,
      lng,
      ...rest
    },
    ref,
  ) => {
    const { isNotNullOrUndefined } = useTypeGuards();
    const parametersAPIClient = useParametersAPIClient();

    const getParametersInputList = (): Promise<APIRequestResult<Array<Parameter>>> =>
      tenant ? parametersAPIClient.listForTenant(parameters, tenant) : parametersAPIClient.list(parameters);

    const [localParameters, localParametersLoaded] = useArraySessionStorage<Parameter>(
      getParametersInputList,
      tenant ? `${tenant}_${parameters}` : parameters,
    );

    const getParametersInputData = (): Promise<Array<SearchInputData>> => {
      const parametersInputData = localParameters.map((d: any): SearchInputData | undefined => {
        if (filterPropertyName && filterValue) {
          const filterProperty = d[filterPropertyName];

          if (filterProperty && filterProperty.toString() !== filterValue.toString()) return;
        }

        if (parameters === ParameterType.flags || ParameterType.prospectFlags) {
          return {
            value: d.id,
            text: lng === 'en' ? d.descriptionEn : d.description,
            icon: d.icon,
          };
        }

        return {
          value: d.id,
          text: lng === 'en' ? d.descriptionEn : d.description,
        };
      });

      return Promise.resolve(parametersInputData.filter(isNotNullOrUndefined));
    };

    if (!localParametersLoaded) return <></>;

    return (
      <SearchInput
        fetch={getParametersInputData}
        onChange={onChange}
        selectedOptions={selectedOptions}
        value={value}
        lng={lng}
        ref={ref}
        {...rest}
      />
    );
  },
);
