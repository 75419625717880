import { useBaseAPIClient, APIRequestResult } from './BaseAPIClient';
import {
  AddGroupCompanies,
  AddGroupJobOffers,
  AddGroupMembers,
  DailyAssignation,
  DailyReportHistory,
  Group,
  GroupCompany,
  GroupJobOffer,
  GroupMember,
  JobOfferDailyAssignation,
  NewGroup,
  DailyReport,
  FiltersDailyReports,
  Status,
} from '../../components/Groups/types';
import { KeyValuePair } from '../../components/Common';
import dayjs from 'dayjs';

type GroupsAPIClient = {
  create: (group: NewGroup) => Promise<APIRequestResult<Group>>;
  list: (
    page: number,
    pageSize: number,
    statusesFilter?: Array<Status>,
    filter?: string,
  ) => Promise<APIRequestResult<Array<Group>>>;
  count: (statusesFilter?: Array<Status>, filter?: string) => Promise<APIRequestResult<number>>;
  get: (id: number) => Promise<APIRequestResult<Group>>;
  update: (id: number, group: Group) => Promise<APIRequestResult<Group>>;
  getStatuses: () => Promise<APIRequestResult<Array<KeyValuePair>>>;
  remove: (id: number) => Promise<APIRequestResult>;
  removeMember: (id: number, userId: string) => Promise<APIRequestResult>;
  addMembers: (id: number, addGroupMembers: AddGroupMembers) => Promise<APIRequestResult<Array<GroupMember>>>;
  removeCompany: (id: number, companyId: number) => Promise<APIRequestResult>;
  addCompanies: (id: number, addGroupCompanies: AddGroupCompanies) => Promise<APIRequestResult<Array<GroupCompany>>>;
  removeJobOffer: (id: number, jobOfferId: number) => Promise<APIRequestResult>;
  addJobOffers: (id: number, addGroupJobOffers: AddGroupJobOffers) => Promise<APIRequestResult<Array<GroupJobOffer>>>;
  asKeyValues: (filter?: string) => Promise<APIRequestResult<Array<KeyValuePair>>>;
  open: (id: number) => Promise<APIRequestResult<Group>>;
  close: (id: number) => Promise<APIRequestResult<Group>>;
  clone: (id: number) => Promise<APIRequestResult<Group>>;
  addDailyAssignation: (groupId: number, jobOfferId: number) => Promise<APIRequestResult<JobOfferDailyAssignation>>;
  removeDailyAssignation: (groupId: number, jobOfferId: number) => Promise<APIRequestResult>;
  getDailyReport: (filters: FiltersDailyReports) => Promise<APIRequestResult<Array<DailyReportHistory>>>;
  getDailyAssignations: () => Promise<APIRequestResult<Array<DailyAssignation>>>;
  addDailyReport: (addDailyReport: DailyReport) => Promise<APIRequestResult<DailyReportHistory>>;
  getUnassignedJobOffers: (date: string) => Promise<APIRequestResult<Array<GroupJobOffer>>>;
  updateReferent: (id: number, userId: number) => Promise<APIRequestResult<void>>;
  removeReferent: (id: number) => Promise<APIRequestResult<void>>;
};

export const useGroupsAPIClient = (origin?: string): GroupsAPIClient => {
  const {
    putRequest,
    postRequest,
    getRequest,
    deleteRequest,
    buildPaginatedUrl,
    getFormatedFromDate,
    getFormatedToDate,
  } = useBaseAPIClient({ origin });

  const formatFilters = (filters: FiltersDailyReports): FiltersDailyReports => {
    const formatedFilters = { ...filters };

    formatedFilters.from = getFormatedFromDate(filters.from);
    formatedFilters.to = getFormatedToDate(filters.to);

    return formatedFilters;
  };

  const create = (group: NewGroup): Promise<APIRequestResult<Group>> => postRequest<Group>('groups', group);

  const list = (
    page: number,
    pageSize: number,
    statusesFilter?: Array<Status>,
    filter?: string,
  ): Promise<APIRequestResult<Array<Group>>> => {
    let url = buildPaginatedUrl('groups', page, pageSize);

    if (filter) {
      url = `${url}&filter=${encodeURIComponent(filter)}`;
    }

    if (statusesFilter) {
      statusesFilter.forEach(s => {
        url += `&statuses=${encodeURIComponent(s)}`;
      });
    }

    return getRequest(url);
  };

  const count = (statusesFilter?: Array<Status>, filter?: string): Promise<APIRequestResult<number>> => {
    let url = 'groups/count?1=1';

    if (filter) {
      url = `${url}&filter=${encodeURIComponent(filter)}`;
    }

    if (statusesFilter) {
      statusesFilter.forEach(s => {
        url += `&statuses=${encodeURIComponent(s)}`;
      });
    }

    return getRequest(url);
  };

  const get = (id: number): Promise<APIRequestResult<Group>> => getRequest<Group>(`groups/${id}`);

  const open = (id: number): Promise<APIRequestResult<Group>> => putRequest<Group>(`groups/${id}/open`);

  const close = (id: number): Promise<APIRequestResult<Group>> => putRequest<Group>(`groups/${id}/close`);

  const clone = (id: number): Promise<APIRequestResult<Group>> => postRequest<Group>(`groups/${id}/clone`);

  const update = (id: number, group: Group): Promise<APIRequestResult<Group>> =>
    putRequest<Group>(`groups/${id}`, group);

  const remove = (id: number): Promise<APIRequestResult> => deleteRequest(`groups/${id}`);

  const removeMember = (id: number, userId: string): Promise<APIRequestResult> =>
    deleteRequest(`groups/${id}/member/${userId}`);

  const addMembers = (id: number, addGroupMembers: AddGroupMembers): Promise<APIRequestResult<Array<GroupMember>>> =>
    putRequest(`groups/${id}/members`, addGroupMembers);

  const removeCompany = (id: number, companyId: number): Promise<APIRequestResult> =>
    deleteRequest(`groups/${id}/company/${companyId}`);

  const getStatuses = (): Promise<APIRequestResult<Array<KeyValuePair>>> =>
    getRequest<Array<KeyValuePair>>('groups/statuses');

  const addCompanies = (
    id: number,
    addGroupCompanies: AddGroupCompanies,
  ): Promise<APIRequestResult<Array<GroupCompany>>> => putRequest(`groups/${id}/companies`, addGroupCompanies);

  const removeJobOffer = (id: number, jobOfferId: number): Promise<APIRequestResult> =>
    deleteRequest(`groups/${id}/job-offer/${jobOfferId}`);

  const addJobOffers = (
    id: number,
    addGroupJobOffers: AddGroupJobOffers,
  ): Promise<APIRequestResult<Array<GroupJobOffer>>> => putRequest(`groups/${id}/job-offers`, addGroupJobOffers);

  const asKeyValues = (filter?: string): Promise<APIRequestResult<Array<KeyValuePair>>> => {
    let url = 'groups/askeyvalues';

    if (filter) {
      url = `${url}&filter=${encodeURIComponent(filter)}`;
    }

    return getRequest<Array<KeyValuePair>>(url);
  };

  const addDailyAssignation = (
    groupId: number,
    jobOfferId: number,
  ): Promise<APIRequestResult<JobOfferDailyAssignation>> => {
    return putRequest<JobOfferDailyAssignation>(`groups/${groupId}/job-offer/${jobOfferId}/daily-assignation`);
  };

  const removeDailyAssignation = (groupId: number, jobOfferId: number): Promise<APIRequestResult> => {
    return deleteRequest(`groups/${groupId}/job-offer/${jobOfferId}/daily-assignation`);
  };

  const getDailyReport = (filters: FiltersDailyReports): Promise<APIRequestResult<Array<DailyReportHistory>>> => {
    return postRequest<Array<DailyReportHistory>>('groups/daily-reports', formatFilters(filters));
  };

  const getDailyAssignations = (): Promise<APIRequestResult<Array<DailyAssignation>>> => {
    return getRequest<Array<DailyAssignation>>('groups/daily-assignations');
  };

  const addDailyReport = (dailyReport: DailyReport): Promise<APIRequestResult<DailyReportHistory>> => {
    return postRequest<DailyReportHistory>('groups/daily-report', dailyReport);
  };

  const getUnassignedJobOffers = (date: string): Promise<APIRequestResult<Array<GroupJobOffer>>> => {
    return getRequest<Array<GroupJobOffer>>(`groups/daily-assignation/${dayjs(date).format('YYYY-MM-DD')}/unassigned`);
  };

  const updateReferent = (id: number, userId: number): Promise<APIRequestResult<void>> => {
    return putRequest(`groups/${id}/referent/${userId}`);
  };

  const removeReferent = (id: number): Promise<APIRequestResult<void>> => {
    return deleteRequest(`groups/${id}/referent`);
  };

  return {
    create,
    get,
    open,
    close,
    list,
    getStatuses,
    count,
    update,
    remove,
    clone,
    removeMember,
    addMembers,
    removeJobOffer,
    addJobOffers,
    removeCompany,
    addCompanies,
    asKeyValues,
    addDailyAssignation,
    removeDailyAssignation,
    getDailyReport,
    getDailyAssignations,
    addDailyReport,
    getUnassignedJobOffers,
    updateReferent,
    removeReferent,
  };
};
