import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { Input } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modules, Pages, PermissionType, TargetType } from '../../../routerTypes';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '../../../hooks/auth/Auth0Provider';
import { MenuItemType } from 'antd/es/menu/hooks/useItems';

type QuickActionsProps = {
  onAddGroupDrawerVisible: () => void;
  onAddInterviewDrawerVisible: () => void;
};

const QuickActions: React.FC<QuickActionsProps> = ({ onAddGroupDrawerVisible, onAddInterviewDrawerVisible }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userHasPermission } = useAuth0();

  const buildMenuItems = (): MenuItemType[] => {
    const items: MenuItemType[] = [];

    const addItem = (permission: string, label: string, key: string, onClick: () => void) => {
      if (userHasPermission(permission)) {
        items.push({ label, key, icon: <PlusOutlined />, onClick });
      }
    };

    const menuConfig = [
      {
        permission: `${PermissionType.create}:${TargetType.candidates}`,
        label: t('addCandidate'),
        key: '1',
        onClick: () => navigate(`${Pages[Modules.candidates].path}/add`),
      },
      {
        permission: `${PermissionType.create}:${TargetType.jobOffers}`,
        label: t('addJobOffer'),
        key: '2',
        onClick: () => navigate(`${Pages[Modules.jobOffers].path}/add`),
      },
      {
        permission: `${PermissionType.create}:${TargetType.companies}`,
        label: t('addCompany'),
        key: '3',
        onClick: () => navigate(`${Pages[Modules.companies].path}/add`),
      },
      {
        permission: `${PermissionType.create}:${TargetType.interviews}`,
        label: t('addInterview'),
        key: '4',
        onClick: onAddInterviewDrawerVisible,
      },
      {
        permission: `${PermissionType.create}:${TargetType.groups}`,
        label: `${t('add')} ${t('group')}`,
        key: '5',
        onClick: onAddGroupDrawerVisible,
      },
      {
        permission: `${PermissionType.create}:${TargetType.prospectCompanies}`,
        label: t('addProspectCompany'),
        key: '6',
        onClick: () => navigate(`${Pages[Modules.prospectCompanies].path}/add`),
      },
      {
        permission: `${PermissionType.create}:${TargetType.prospectClients}`,
        label: t('addProspectClient'),
        key: '7',
        onClick: () => navigate(`${Pages[Modules.prospectClients].path}/add`),
      },
    ];

    menuConfig.forEach(({ permission, label, key, onClick }) => addItem(permission, label, key, onClick));

    return items;
  };

  const items = buildMenuItems();
  return (
    <div>
      <Input
        size='large'
        placeholder={t('searchQuickAction')}
        style={{ background: '#eee' }}
        bordered={false}
        prefix={<SearchOutlined style={{ color: '#888' }} />}
        allowClear
      />
      <Menu items={items} />
    </div>
  );
};

export default QuickActions;
