import React, { useState, useEffect, useRef, RefObject } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import usePrompt from '../../hooks/usePrompt';
import { useTranslation } from 'react-i18next';
import { Menu, Button } from 'antd';
import {
  ShopOutlined,
  TagsOutlined,
  UserOutlined,
  IdcardOutlined,
  PieChartOutlined,
  BarsOutlined,
  FolderOpenOutlined,
  SettingOutlined,
  TeamOutlined,
  BookOutlined,
  ReconciliationOutlined,
} from '@ant-design/icons';
import { findKey } from 'lodash';
import PrivateMenuItem from '../Auth/PrivateMenuItem';
import { useSharedContext, useViewport } from '../../hooks';
import { PrivateSubMenu } from '../Auth';
import { useAuth0 } from '../../hooks/auth/Auth0Provider';
import { TargetType, PermissionType, Modules, Pages } from '../../routerTypes';
import { CandidatesStatusType } from '../Candidates/types';
import { Plans, Roles } from '../../hooks/auth/types';
import { ProspectClientType } from '../ProspectClients/types';
import { ProspectCompanyType } from '../ProspectCompanies/types';
import TenantIcon from '../Common/Icons/TenantIcon';
import './css/SiderMenu.css';

type SiderMenuProps = {
  closeMenu?: () => void;
};

export const SiderMenu: React.FC<SiderMenuProps> = ({ closeMenu }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { formHasChanges, setFormHasChanges, globalFilter } = useSharedContext();
  const { userIsInRole } = useAuth0();
  const { isDesktop } = useViewport();
  const newRef = useRef(null);

  const [selectedTab, setSelectedTab] = useState<CandidatesStatusType | string>(CandidatesStatusType.all);

  const setCurrentTab = () => {
    const locationKeys = location.pathname.split('/');

    if (
      !Pages[Modules.candidates].path.includes(locationKeys[1]) &&
      !Pages[Modules.prospectClients].path.includes(locationKeys[1]) &&
      !Pages[Modules.prospectClients].path.includes(locationKeys[1])
    ) {
      setSelectedTab(locationKeys[1] as CandidatesStatusType | string);
    } else {
      if (Pages[Modules.candidates].path.includes(locationKeys[1])) {
        if (findKey(CandidatesStatusType, o => o === locationKeys[2])) {
          setSelectedTab(locationKeys[2] as CandidatesStatusType);
        } else {
          setSelectedTab(CandidatesStatusType.all);
        }
      }
      if (Pages[Modules.prospectClients].path.includes(locationKeys[1])) {
        if (findKey(ProspectClientType, o => o === locationKeys[2])) {
          setSelectedTab(`prospectClients-${locationKeys[2]}` as string);
        } else {
          setSelectedTab(locationKeys[1]);
        }
      }

      if (Pages[Modules.prospectCompanies].path.includes(locationKeys[1])) {
        if (findKey(ProspectCompanyType, o => o === locationKeys[2])) {
          setSelectedTab(`prospectCompanies-${locationKeys[2]}` as string);
        } else {
          setSelectedTab(locationKeys[1]);
        }
      }
    }
  };

  const handleOutsideClick = e => {
    if (
      newRef?.current &&
      !(newRef as RefObject<HTMLDivElement>)?.current?.contains(e.target) &&
      e?.target?.parentNode?.className !== 'anticon anticon-menu'
    ) {
      closeMenu && closeMenu();
    }
  };

  useEffect(() => {
    setFormHasChanges(false);
    setCurrentTab();
  }, [location]);

  useEffect(() => {
    if (!isDesktop()) {
      document.addEventListener('mousedown', handleOutsideClick);
      return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
      };
    }
  });

  const getMenuItemKey = (module: Modules): string => {
    return Pages[module].path.trim().split('/')[1];
  };

  const navigateTo = (target: string, param?: string) => {
    let filterUrl = '';
    let paramUrl = '';
    const baseUrl = Pages[target].path;

    if (param) {
      paramUrl = `/${param}`;
    }

    if (baseUrl.split('/')[1] !== location.pathname.split('/')[1]) {
      filterUrl = '';
    } else if (globalFilter) {
      filterUrl = `?filter=${encodeURIComponent(globalFilter)}`;
    }

    navigate(`${baseUrl}${paramUrl}${filterUrl}`);
  };

  usePrompt(t('promptMessage'), formHasChanges);

  return (
    <div id='SiderMenu'>
      {isDesktop() && (
        <div className='logo-container'>
          <Button
            type='link'
            style={{ display: 'contents' }}
            title={`WebstartedHR - v${process.env.REACT_APP_VERSION}`}
            onClick={() =>
              navigateTo(
                userIsInRole(Roles.companyAdministrator) ? Modules.companyView : Modules.candidates,
                CandidatesStatusType.all,
              )
            }
          >
            <TenantIcon />
          </Button>
        </div>
      )}
      <div ref={newRef}>
        <Menu
          theme='dark'
          selectedKeys={[selectedTab]}
          className='menu-container'
          style={{ paddingBottom: isDesktop() ? '50px' : '20px' }}
          mode='inline'
          triggerSubMenuAction={isDesktop() ? 'hover' : 'click'}
        >
          {isDesktop() && (
            <PrivateMenuItem
              key={getMenuItemKey(Modules.dashboard)}
              roles={[
                Roles.recruiter,
                Roles.administrator,
                Roles.recruiterManager,
                Roles.salesAgent,
                Roles.externalRecruiterManager,
              ]}
              excludedPlans={[Plans.silver]}
              showUpSell
              icon={<PieChartOutlined />}
              className='dashboard'
              onClick={() => navigateTo(Modules.dashboard)}
            >
              {t('dashboard')}
            </PrivateMenuItem>
          )}
          <PrivateSubMenu
            key={getMenuItemKey(Modules.candidates)}
            excludedRoles={[Roles.companyAdministrator]}
            permissions={[`${PermissionType.read}:${TargetType.candidates}`]}
            icon={<UserOutlined />}
            title={t('candidates')}
            popupClassName='candidates-sub-menu'
            className='candidates'
            onClick={() => {
              isDesktop() && navigateTo(Modules.candidates);
            }}
          >
            <div
              style={{
                margin: '15px 5px 0 5px',
                borderBottom: '1px solid #9e9e9e97',
                textAlign: 'start',
              }}
            >
              <p style={{ marginLeft: '12px' }}>{t('candidates')}</p>
            </div>
            <PrivateMenuItem
              key={CandidatesStatusType.pool}
              roles={[
                Roles.administrator,
                Roles.recruiterManager,
                Roles.recruiterPoolManager,
                Roles.recruitingReferent,
              ]}
              excludedPlans={[Plans.silver]}
              className='tab'
              onClick={() => {
                navigateTo(Modules.candidates, CandidatesStatusType.pool);
                closeMenu && closeMenu();
              }}
            >
              {t(CandidatesStatusType.pool).toUpperCase()}
            </PrivateMenuItem>
            <Menu.Item
              key={CandidatesStatusType.sourced}
              onClick={() => {
                navigateTo(Modules.candidates, CandidatesStatusType.sourced);
                closeMenu && closeMenu();
              }}
              className='tab'
            >
              {t(CandidatesStatusType.sourced).toUpperCase()}
            </Menu.Item>
            <Menu.Item
              key={CandidatesStatusType.active}
              onClick={() => {
                navigateTo(Modules.candidates, CandidatesStatusType.active);
                closeMenu && closeMenu();
              }}
              className='tab'
            >
              {t(CandidatesStatusType.active).toUpperCase()}
            </Menu.Item>
            <Menu.Item
              key={CandidatesStatusType.ready}
              onClick={() => {
                navigateTo(Modules.candidates, CandidatesStatusType.ready);
                closeMenu && closeMenu();
              }}
              className='tab'
            >
              {t(CandidatesStatusType.ready).toUpperCase()}
            </Menu.Item>
            <Menu.Item
              key={CandidatesStatusType.hired}
              onClick={() => {
                navigateTo(Modules.candidates, CandidatesStatusType.hired);
                closeMenu && closeMenu();
              }}
              className='tab'
            >
              {t(CandidatesStatusType.hired).toUpperCase()}
            </Menu.Item>
            <Menu.Item
              key={CandidatesStatusType.onHold}
              onClick={() => {
                navigateTo(Modules.candidates, CandidatesStatusType.onHold);
                closeMenu && closeMenu();
              }}
              className='tab'
            >
              {t(CandidatesStatusType.onHold).toUpperCase()}
            </Menu.Item>
            <Menu.Item
              key={CandidatesStatusType.all}
              onClick={() => {
                navigateTo(Modules.candidates, CandidatesStatusType.all);
                closeMenu && closeMenu();
              }}
              className='tab'
            >
              {t(CandidatesStatusType.all).toUpperCase()}
            </Menu.Item>
          </PrivateSubMenu>
          <PrivateMenuItem
            key={getMenuItemKey(Modules.jobOffers)}
            excludedRoles={[Roles.companyAdministrator]}
            permissions={[`${PermissionType.read}:${TargetType.jobOffers}`]}
            icon={<TagsOutlined />}
            className='jobOffers'
            onClick={() => {
              navigateTo(Modules.jobOffers);
              closeMenu && closeMenu();
            }}
          >
            {t('jobOffers')}
          </PrivateMenuItem>
          <PrivateMenuItem
            key={getMenuItemKey(Modules.companyView)}
            roles={[Roles.companyAdministrator]}
            icon={<TagsOutlined />}
            className='jobOffers'
            onClick={() => {
              navigate(`${Pages[Modules.companyView].path}${Pages[Modules.jobOffers].path}`);
              closeMenu && closeMenu();
            }}
          >
            {t('jobOffers')}
          </PrivateMenuItem>
          <PrivateMenuItem
            key={getMenuItemKey(Modules.companies)}
            permissions={[`${PermissionType.read}:${TargetType.companies}`]}
            icon={<ShopOutlined />}
            className='companies'
            onClick={() => {
              navigateTo(Modules.companies);
              closeMenu && closeMenu();
            }}
          >
            {t('companies')}
          </PrivateMenuItem>
          <PrivateMenuItem
            key={getMenuItemKey(Modules.interviews)}
            permissions={[`${PermissionType.read}:${TargetType.interviews}`]}
            excludedRoles={[Roles.companyAdministrator]}
            icon={<IdcardOutlined />}
            className='interviews'
            onClick={() => {
              navigateTo(Modules.interviews);
              closeMenu && closeMenu();
            }}
          >
            {t('interviews')}
          </PrivateMenuItem>
          <PrivateMenuItem
            key={getMenuItemKey(Modules.scheduledTasks)}
            permissions={[`${PermissionType.read}:${TargetType.candidates}`]}
            icon={<BarsOutlined />}
            excludedRoles={[Roles.companyAdministrator]}
            excludedPlans={[Plans.silver]}
            showUpSell
            className='tasks'
            onClick={() => {
              navigateTo(Modules.scheduledTasks);
              closeMenu && closeMenu();
            }}
          >
            {t('tasks')}
          </PrivateMenuItem>
          <PrivateMenuItem
            key={getMenuItemKey(Modules.jobApplications)}
            roles={[
              Roles.administrator,
              Roles.recruiterManager,
              Roles.recruiter,
              Roles.companyExecutive,
              Roles.externalRecruiterManager,
            ]}
            icon={<FolderOpenOutlined />}
            className='jobApplications'
            onClick={() => {
              navigateTo(Modules.jobApplications);
              closeMenu && closeMenu();
            }}
          >
            {t('jobApplications')}
          </PrivateMenuItem>
          {isDesktop() && (
            <PrivateMenuItem
              key={getMenuItemKey(Modules.groups)}
              excludedPlans={[Plans.silver, Plans.gold]}
              permissions={[`${PermissionType.read}:${TargetType.groups}`]}
              showUpSell
              icon={<TeamOutlined />}
              className='groups'
              onClick={() => {
                navigateTo(Modules.groups);
                closeMenu && closeMenu();
              }}
            >
              {t('groups')}
            </PrivateMenuItem>
          )}
          <PrivateSubMenu
            key={getMenuItemKey(Modules.prospectCompanies)}
            excludedPlans={[Plans.silver, Plans.gold]}
            showUpSell
            permissions={[`${PermissionType.read}:${TargetType.prospectCompanies}`]}
            icon={<BookOutlined />}
            title={t('prospectCompanies')}
            popupClassName='prospectCompanies-sub-menu'
            className='prospectCompanies'
            onClick={() => {
              isDesktop() && navigateTo(Modules.prospectCompanies);
            }}
          >
            <div style={{ margin: '15px 5px 0 5px', borderBottom: '1px solid #9e9e9e97', textAlign: 'start' }}>
              <p style={{ marginLeft: '12px' }}>{t('prospectCompanies')}</p>
            </div>
            <PrivateMenuItem
              key={`prospectCompanies-${ProspectCompanyType.pool}`}
              excludedPlans={[Plans.silver, Plans.gold]}
              permissions={[`${PermissionType.read}:${TargetType.prospectCompanies}`]}
              className='tab'
              onClick={() => {
                navigateTo(Modules.prospectCompanies, ProspectCompanyType.pool);
                closeMenu && closeMenu();
              }}
            >
              {t(ProspectCompanyType.pool).toUpperCase()}
            </PrivateMenuItem>
            <PrivateMenuItem
              key={`prospectCompanies-${ProspectCompanyType.all}`}
              excludedPlans={[Plans.silver, Plans.gold]}
              permissions={[`${PermissionType.read}:${TargetType.prospectCompanies}`]}
              className='tab'
              onClick={() => {
                navigateTo(Modules.prospectCompanies, ProspectCompanyType.all);
                closeMenu && closeMenu();
              }}
            >
              {t(ProspectCompanyType.all).toUpperCase()}
            </PrivateMenuItem>
          </PrivateSubMenu>
          <PrivateSubMenu
            key={getMenuItemKey(Modules.prospectClients)}
            excludedPlans={[Plans.silver, Plans.gold]}
            showUpSell
            permissions={[`${PermissionType.read}:${TargetType.prospectClients}`]}
            icon={<ReconciliationOutlined />}
            title={t('prospectClients')}
            popupClassName='prospectClients-sub-menu'
            className='prospectClients'
            onClick={() => {
              isDesktop() && navigateTo(Modules.prospectClients);
            }}
          >
            <div
              style={{
                margin: '15px 5px 0 5px',
                borderBottom: '1px solid #9e9e9e97',
                textAlign: 'start',
              }}
            >
              <p style={{ marginLeft: '12px' }}>{t('prospectClients')}</p>
            </div>
            <PrivateMenuItem
              key={`prospectClients-${ProspectClientType.pool}`}
              excludedPlans={[Plans.silver, Plans.gold]}
              permissions={[`${PermissionType.read}:${TargetType.prospectClients}`]}
              className='tab'
              onClick={() => {
                navigateTo(Modules.prospectClients, ProspectClientType.pool);
                closeMenu && closeMenu();
              }}
            >
              {t(ProspectClientType.pool).toUpperCase()}
            </PrivateMenuItem>
            <PrivateMenuItem
              key={`prospectClients-${ProspectClientType.all}`}
              excludedPlans={[Plans.silver, Plans.gold]}
              permissions={[`${PermissionType.read}:${TargetType.prospectClients}`]}
              className='tab'
              onClick={() => {
                navigateTo(Modules.prospectClients, ProspectClientType.all);
                closeMenu && closeMenu();
              }}
            >
              {t(ProspectClientType.all).toUpperCase()}
            </PrivateMenuItem>
          </PrivateSubMenu>
          <PrivateMenuItem
            key={getMenuItemKey(Modules.talentNetworkList)}
            excludedRoles={[Roles.companyAdministrator]}
            roles={[Roles.administrator, Roles.recruiterManager, Roles.talentNetworkViewer]}
            webstartedTenant={true}
            icon={
              <img
                style={{ height: '16px', width: '18px' }}
                src={location.pathname.startsWith('/talent-network') ? '/logo-TN-white.png' : '/logo-TN-grey.png'}
              />
            }
            className='talent-network'
            onClick={() => {
              navigateTo(Modules.talentNetworkList);
              closeMenu && closeMenu();
            }}
          >
            Talent Network
          </PrivateMenuItem>
          <PrivateMenuItem
            key={getMenuItemKey(Modules.webstartedAI)}
            roles={[Roles.administrator]}
            icon={
              <img
                src={location.pathname.startsWith('/webstarted-AI') ? '/logo-AI-white.png' : '/logo-AI-grey.png'}
                width={18}
              />
            }
            className='webstartedAI'
            onClick={() => {
              if (location.pathname === Pages[Modules.webstartedAI].path) {
                window.location.reload();
              } else {
                navigateTo(Modules.webstartedAI);
              }
              closeMenu && closeMenu();
            }}
          >
            {t('webstartedAI')}
          </PrivateMenuItem>
          {isDesktop() && (
            <PrivateSubMenu
              roles={[Roles.administrator, Roles.recruiterManager, Roles.userAdministrator]}
              icon={<SettingOutlined />}
              title={t('configuration')}
              className='configuration'
            >
              <div
                style={{
                  margin: '15px 5px 0 5px',
                  borderBottom: '1px solid #9e9e9e97',
                  textAlign: 'start',
                }}
              >
                <p style={{ marginLeft: '12px' }}>{t('configuration')}</p>
              </div>
              <PrivateMenuItem
                key={getMenuItemKey(Modules.settings)}
                roles={[Roles.administrator]}
                onClick={() => navigateTo(Modules.settings)}
              >
                {t('settings')}
              </PrivateMenuItem>
              <PrivateMenuItem
                key={getMenuItemKey(Modules.parameters)}
                roles={[Roles.administrator, Roles.recruiterManager]}
                onClick={() => navigateTo(Modules.parameters)}
              >
                {t('parameters')}
              </PrivateMenuItem>
              <PrivateMenuItem
                key={getMenuItemKey(Modules.integrations)}
                roles={[Roles.administrator]}
                excludedPlans={[Plans.silver]}
                onClick={() => navigateTo(Modules.integrations)}
              >
                {t('integrations')}
              </PrivateMenuItem>
              <PrivateMenuItem
                key={getMenuItemKey(Modules.usersManagement)}
                excludedPlans={[Plans.silver]}
                roles={[Roles.userAdministrator]}
                onClick={() => navigateTo(Modules.usersManagement, 'users')}
              >
                {t('usersManagement')}
              </PrivateMenuItem>
            </PrivateSubMenu>
          )}
        </Menu>
      </div>
    </div>
  );
};

export default SiderMenu;
