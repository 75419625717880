import React, { useEffect, useState } from 'react';
import { Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  IconButton,
  KeyValuePair,
  Loading,
  SearchInput,
  SearchInputData,
  SelectForEnum,
  SelectWithSearch,
  TextEditor,
} from '../../Common';
import { useCandidatesAPIClient, useInterviewsAPIClient, useJobApplicationsAPIClient } from '../../../hooks/api';
import { useArraySessionStorage, useErrorMessageHandler, useUserRoles } from '../../../hooks';
import { InterviewType, Source } from '../types';
import { SessionStorageKeys } from '../../../constants';
import { useUsersInfo } from '../../../hooks/UseUsersInfo';
import { Plans, Roles } from '../../../hooks/auth/types';
import { useAuth0 } from '../../../hooks/auth/Auth0Provider';

interface InformationPanelProps {
  interviewId?: number;
  candidateOwnedByUserId?: string;
  candidateId?: number;
  source?: number;
}
export const InformationPanel: React.FC<InformationPanelProps> = ({
  candidateId,
  source,
  interviewId,
  candidateOwnedByUserId,
}) => {
  const [currentCandidateId, setCurrentCandidateId] = useState<number | undefined>(candidateId);
  const [currentSource, setCurrentSource] = useState<number>();
  const { userIsManager } = useUserRoles();
  const { userIsInRole, user, userIsInPlan, getCurrentUserTenant } = useAuth0();
  const currentTenant = getCurrentUserTenant();

  useEffect(() => {
    if (candidateId) {
      setCurrentCandidateId(candidateId);
    }
    if (source) {
      setCurrentSource(source);
    }
  }, [candidateId, source]);

  const { t } = useTranslation();
  const { Option } = Select;
  const { TextArea } = Input;

  const { getUserSearchInputList } = useUsersInfo();
  const { errorMessageHandler } = useErrorMessageHandler();

  const interviewsAPIClient = useInterviewsAPIClient();
  const candidatesAPIClient = useCandidatesAPIClient();
  const jobApplicationsAPIClient = useJobApplicationsAPIClient();

  const [statuses, statusesLoaded] = useArraySessionStorage<KeyValuePair>(
    interviewsAPIClient.getStatuses,
    SessionStorageKeys.INTERVIEW_STATUSES,
  );

  const userCanUpdateInterviewStatus = (): boolean => {
    return (
      userIsManager() ||
      userIsInRole([Roles.interviewer, Roles.externalRecruiterManager, Roles.externalInterviewer]) ||
      (userIsInRole(Roles.recruiter) &&
        Source.client &&
        (candidateOwnedByUserId === user.email || candidateOwnedByUserId === currentTenant))
    );
  };

  const getCandidates = async (filter?: string): Promise<Array<SearchInputData>> => {
    const res = await candidatesAPIClient.list(1, 15, filter);
    if (res.hasError) {
      errorMessageHandler(res);
      return [];
    }
    if (!res.data) return [];
    return res.data.map(d => {
      return {
        value: d.id,
        text: `${d.linkedInProfile ? `${d.fullName} - (${d.linkedInProfile})` : `${d.fullName}`}`,
      };
    });
  };

  const getJobApplications = async (filter?: string): Promise<Array<SearchInputData>> => {
    if (!currentCandidateId) return [];

    const res = await jobApplicationsAPIClient.getByCandidate(currentCandidateId, 1, 1000);
    if (res.hasError) {
      errorMessageHandler(res);
      return [];
    }
    if (!res.data) return [];
    return res.data.map(d => {
      return {
        value: d.id,
        text: `${d.jobOfferTitle} ${d.companyName ? `(${d.companyName})` : ''}`,
      };
    });
  };

  const getInterviewersFormInput = () => {
    if (userIsInPlan(Plans.silver)) return null;

    if (currentSource === Source.client)
      return (
        <Col span={24}>
          <Form.Item label={t('interviewers')} name='clientInterviewers'>
            <TextArea rows={4} />
          </Form.Item>
        </Col>
      );

    return (
      <Col span={24}>
        <Form.Item label={t('interviewers')} name='interviewerUserIds'>
          <SearchInput placeholder={t('userEmail')} fetch={getUserSearchInputList} mode='multiple' />
        </Form.Item>
      </Col>
    );
  };

  if (!statusesLoaded) return <Loading />;

  return (
    <>
      <Row gutter={32}>
        {!candidateId && (
          <Col span={24}>
            <Form.Item
              label={`${t('candidate')}`}
              name='candidateId'
              rules={[{ required: true, message: `${t('select')} ${t('candidate')}` }]}
            >
              <SearchInput fetch={getCandidates} remoteSearch onChange={(e: number) => setCurrentCandidateId(e)} />
            </Form.Item>
          </Col>
        )}
        <Col span={24}>
          <Form.Item
            label={t('source')}
            name='source'
            rules={[
              {
                required: true,
                message: `${t('pleaseInput')} ${t('source')}!`,
              },
            ]}
          >
            <SelectForEnum
              placeholder={`${t('select')}`}
              enumType={Source}
              onChange={(e: number) => setCurrentSource(e)}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label={`${t('jobApplication')}`}
            name='jobApplicationId'
            rules={[{ required: currentSource === Source.client, message: `${t('select')} ${t('jobApplication')}` }]}
          >
            <SearchInput
              placeholder={t('select')}
              fetch={getJobApplications}
              initOn={currentCandidateId}
              remoteSearch={false}
            />
          </Form.Item>
        </Col>
        {getInterviewersFormInput()}
        <Col span={12}>
          <Form.Item
            label={t('type')}
            name='type'
            rules={[{ required: true, message: `${t('pleaseInput')} ${t('type')}!` }]}
          >
            <SelectForEnum placeholder={`${t('select')}`} enumType={InterviewType} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t('status')}
            name='status'
            hidden={!!interviewId && !userCanUpdateInterviewStatus()}
            rules={[
              {
                required: true,
                message: `${t('pleaseInput')} ${t('status')}!`,
              },
            ]}
          >
            <SelectWithSearch placeholder={`${t('select')}`}>
              {statuses.map(data => (
                <Option key={data.key} value={data.key}>
                  {data.value}
                </Option>
              ))}
            </SelectWithSearch>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t('date')}
            name='scheduledAt'
            rules={[{ required: true, message: `${t('pleaseInput')} ${t('date')}!` }]}
          >
            <DatePicker
              className='scheduledAt'
              format='YYYY-MM-DD HH:mm'
              showTime={{
                minuteStep: 15,
              }}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t('notes')} name='notes'>
            <TextEditor />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={32}>
        <Col span={24} className='save-button'>
          <IconButton style={{ width: '9rem' }} type='primary' htmlType='submit'>
            {t('save')}
          </IconButton>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(InformationPanel);
