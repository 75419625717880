import { useBaseAPIClient, APIRequestResult } from './index';
import { NotificationList, NotificationType } from '../UseNotificationsContext';

type NotificationsAPIClient = {
  listUnread: (page: number, pageSize: number) => Promise<APIRequestResult<NotificationList>>;
  countUnread: () => Promise<APIRequestResult<number>>;
  listUnreadByType: (
    page: number,
    pageSize: number,
    typeListItem: Array<NotificationType>,
  ) => Promise<APIRequestResult<NotificationList>>;
  read: (ids: Array<NotificationType>) => Promise<APIRequestResult>;
  readOne: (id: number) => Promise<APIRequestResult>;
  readAll: () => Promise<APIRequestResult>;
};

export const useNotificationsAPIClient = (origin?: string): NotificationsAPIClient => {
  const { putRequest, getRequest, buildPaginatedUrl } = useBaseAPIClient({ origin });

  const listUnread = (page: number, pageSize: number): Promise<APIRequestResult<NotificationList>> =>
    getRequest<NotificationList>(buildPaginatedUrl('notifications/unread/', page, pageSize));

  const countUnread = (): Promise<APIRequestResult<number>> => getRequest('notifications/unread/count');

  const listUnreadByType = (
    page: number,
    pageSize: number,
    typeListItem: Array<NotificationType>,
  ): Promise<APIRequestResult<NotificationList>> => {
    const typeListQuery = typeListItem.map(type => `types=${encodeURIComponent(type)}`).join('&');
    const url = `${buildPaginatedUrl('notifications/unread/type', page, pageSize)}&${typeListQuery}`;
    return getRequest<NotificationList>(url);
  };

  const read = (types: Array<NotificationType>): Promise<APIRequestResult> =>
    putRequest('notifications/read/type', types);

  const readOne = (id: number): Promise<APIRequestResult> => putRequest(`notifications/read/${id}`, {});

  const readAll = (): Promise<APIRequestResult> => putRequest('notifications/read/all');

  return {
    listUnread,
    countUnread,
    listUnreadByType,
    read,
    readOne,
    readAll,
  };
};
