import { SelectOutlined } from '@ant-design/icons';
import { Avatar, Divider, Modal } from 'antd';
import React, { useState } from 'react';
import QuickActions from './QuickActions';
import { AppDrawer } from '../../Common';
import { DefaultDrawer } from '../../../constants';
import AddGroup from '../../Groups/Add';
import AddInterview from '../../Interviews/Common/Add';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Modules, Pages } from '../../../routerTypes';

const QuickActionsModal: React.FC = () => {
  const [openQuickActions, setOpenQuickActions] = useState(false);
  const [addGroupDrawerVisible, setAddGroupDrawerVisible] = useState(false);
  const [addInterviewDrawerVisible, setAddInterviewDrawerVisible] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const onGroupAdded = () => {
    navigate(`${Pages[Modules.groups].path}`);
    setAddGroupDrawerVisible(false);
  };

  const onAddInterview = (): void => {
    navigate(`${Pages[Modules.interviews].path}/next`);
    setAddInterviewDrawerVisible(true);
  };

  return (
    <div>
      <Avatar
        shape='square'
        className='avatar-calendar'
        size='large'
        onClick={() => setOpenQuickActions(true)}
        icon={
          <SelectOutlined
            style={{
              fontSize: '20px',
              color: 'white',
            }}
          />
        }
      />
      <Modal
        open={openQuickActions}
        onCancel={() => setOpenQuickActions(false)}
        footer={null}
        title={t('quickActions')}
      >
        <Divider />
        <QuickActions
          onAddGroupDrawerVisible={() => setAddGroupDrawerVisible(true)}
          onAddInterviewDrawerVisible={() => setAddInterviewDrawerVisible(true)}
        />
      </Modal>

      <AppDrawer
        width={DefaultDrawer.DRAWER_LARGE_WIDTH}
        title={t('newGroup')}
        onClose={() => setAddGroupDrawerVisible(false)}
        visible={addGroupDrawerVisible}
        overlay
      >
        <AddGroup onCancel={() => setAddGroupDrawerVisible(false)} onGroupAdded={onGroupAdded} />
      </AppDrawer>

      <AppDrawer
        title={t('addInterview')}
        onClose={() => setAddInterviewDrawerVisible(false)}
        visible={addInterviewDrawerVisible}
        overlay
      >
        <AddInterview onInterviewAdded={onAddInterview} />
      </AppDrawer>
    </div>
  );
};

export default QuickActionsModal;
