import React, { ReactNode } from 'react';
import { Avatar, Tooltip } from 'antd';
import { TenantIcon } from './Icons/TenantIcon';
import { FlagWS } from './Flag';
import './css/WSHRAvatar.css';

type WSHRAvatarProps = {
  src?: string;
  size?: number;
  icon?: ReactNode;
  flagSize?: number;
  countryCode?: string;
  countryName?: string;
  avatarStyle?: string;
  text?: string;
};

export const WSHRAvatar: React.FC<WSHRAvatarProps> = props => {
  const { src, size, flagSize, countryCode, countryName, icon, avatarStyle, text } = props;

  const getSource = (src?: string) => {
    if (!src) return src;

    if (src.indexOf('googleusercontent') > -1) return <img src={src} referrerPolicy='no-referrer' />;

    return src;
  };

  return (
    <div id='WSHRAvatar' className='avatar-container'>
      <Tooltip placement='top' title={text}>
        <Avatar
          src={getSource(src)}
          size={size}
          icon={icon || <TenantIcon />}
          className={`avatar ${avatarStyle ? avatarStyle : ''}`}
        />
      </Tooltip>
      {countryCode && (
        <Tooltip placement='top' title={countryName}>
          <FlagWS code={countryCode} height={flagSize || 15} className='avatar-flag' />
        </Tooltip>
      )}
    </div>
  );
};

export default WSHRAvatar;
