import { SearchInputData } from '../components/Common';
import { useCompaniesAPIClient } from './api';
import { useErrorMessageHandler } from './UseErrorMessageHandler';

type UsersInfo = {
  getCompaniesInputData: (filter?: string) => Promise<Array<SearchInputData>>;
};

export const useCompaniesInfo = (): UsersInfo => {
  const { errorMessageHandler } = useErrorMessageHandler();
  const companiesAPIClient = useCompaniesAPIClient();

  const getCompaniesInputData = async (filter?: string): Promise<Array<SearchInputData>> => {
    const res = await companiesAPIClient.asKeyValues(filter);

    if (res.hasError) {
      errorMessageHandler(res);
      return [];
    }

    if (!res.data) return [];

    return res.data.map(d => {
      return {
        value: d.key,
        text: d.value,
      };
    });
  };

  return {
    getCompaniesInputData,
  };
};
