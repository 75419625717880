import { Address } from '../Common/types';
import { ProspectClient } from '../ProspectClients/types';

export type ProspectCompanyListItem = {
  id: number;
  logo?: string;
  country?: string;
  countryName?: string;
  linkedInProfile?: string;
  website?: string;
  name: string;
  status: number;
  statusName: string;
  category?: string;
  notes?: string;
  prospectClientsCount?: number;
  isOwnerNonTransfereable?: boolean;
  importantComments?: string;
  createdAt: string;
  createdByUserName: string;
  updatedAt: string;
  updatedByUserName: string;
  pinned: boolean;
  flags?: Array<ProspectCompanyFlag>;
};

export type ProspectCompanyCreate = Omit<ProspectCompany, 'id'>;

export type ProspectCompany = {
  id: number;
  logo?: string;
  address: Address;
  clients: Array<ProspectClient>;
  name: string;
  status: Status;
  statusName: string;
  linkedInProfile?: string;
  linkedInCompanyId?: string;
  angelList?: string;
  email?: string;
  website?: string;
  category?: string;
  notes?: string;
  importantComments?: string;
  jobOffersUrl?: string;
  type?: number;
  typeName?: string;
  crunchBase?: string;
  phone?: string;
  tags?: Array<string>;
  activitiesCount: number;
  mainContactId?: number;
  scheduledTasksCount: number;
  createdAt: string;
  createdByUserName: string;
  updatedAt: string;
  isOwnerNonTransfereable?: boolean;
  updatedByUserName: string;
  flags?: Array<ProspectCompanyFlag>;
  lastActivityContact?: string;
  lastNote?: string;
  mainContactName?: string;
  mainContactLinkedInProfile?: string;
  age?: number;
  referredBy?: string;
  meetingsCount: number;
  prospectClientsCount: number;
  ownedAt?: string;
  ownedByUserId?: string;
  ownedByUserName?: string;
  ownedByUserPictureUrl?: string;
  ownersHistoryCount: number;
};

export enum ProspectCompanyType {
  pool = 'pool',
  all = 'all',
}

export type ProspectCompanyActivity = {
  prospectCompanyId: number;
  fullName: string;
};

export enum Status {
  pool = 1,
  requestSent,
  startingConversation,
  active,
  notInterested,
  promoted,
}

export type ProspectCompanyFlag = {
  flagId: number;
  icon?: string;
  description?: string;
};

export type ProspectCompanyLinkedIn = ProspectCompany & {
  statusName: string;
  lastActivityContact: string;
  mainContactName: string;
  mainContactId: number;
  age: number;
  industryId?: number;
  industryName?: string;
  pinned: boolean;
  lastActivityContactCreatedAt: string;
  createdByUserId: string;
  tags?: Array<string>;
  importance?: number;
  importanceName?: string;
  updatedByUserId: string;
};

export type AdvancedSearchFilters = {
  statuses: Array<Status>;
  type?: number;
  industryId?: number;
  createdAtFrom?: string;
  createdAtTo?: string;
  updatedAtFrom?: string;
  importance?: number;
  updatedAtTo?: string;
  countries?: Array<string>;
  ownedByUserId?: string;
  ownedAtFrom?: string;
  ownedAtTo?: string;
  createdByUserId?: string;
  tags?: Array<string>;
};

export type ProspectCompanyLinkedInUpdate = {
  id: number;
  type: number;
  industryId?: number;
  importance?: number;
  status?: Status;
  ownedByUserId?: string;
};
