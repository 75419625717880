import React, { useState, useEffect, ReactElement } from 'react';
import { Select } from 'antd';
import ClipLoader from 'react-spinners/ClipLoader';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { SelectProps } from 'antd';
import { SelectWithSearch } from './index';
import CustomIcon from './CustomIcon';
import { isEqual } from 'lodash';

export interface SearchInputData {
  value: number | string;
  text: string;
  icon?: string;
  mode?: 'multiple' | 'tags';
}
export interface SearchInputProps extends SelectProps<string | number | undefined | Array<string | number>> {
  remoteSearch?: boolean;
  showSearch?: boolean;
  initOn?: any;
  disableOnEmpty?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  selectedOptions?: Array<number | string | undefined>;
  lng?: 'es' | 'en';
  onChange?(value: number | string | Array<string | number> | undefined): void;
  fetch: (filter?: string) => Promise<Array<SearchInputData>>;
}

export const SearchInput = React.forwardRef<HTMLSelectElement, SearchInputProps>((props, ref) => {
  type SearchInputData = {
    value: number | string;
    text: string;
    icon?: string;
    mode?: string;
  };

  const {
    value,
    mode,
    remoteSearch,
    showSearch,
    initOn,
    placeholder,
    disableOnEmpty,
    disabled,
    selectedOptions,
    lng,
    fetch,
    onChange,
    ...rest
  } = props;

  const { t } = useTranslation(undefined, { lng });
  const [defaultData, setDefaultData] = useState<Array<SearchInputData>>([]);
  const [options, setOptions] = useState<Array<ReactElement>>([]);
  const [fetching, setFetching] = useState(true);

  const [localValue, setLocalValue] = useState<number | string | Array<string | number> | undefined>();

  const { Option } = Select;

  const filteredOptions = (data: Array<SearchInputData>, selectedOptions?: Array<number | string | undefined>) => {
    if (!selectedOptions) {
      return data;
    }
    return data.filter(d => !selectedOptions.some(r => r == d.value));
  };

  const optionsBuilder = (data: Array<SearchInputData>, selectedOptions?: Array<number | string | undefined>): void => {
    const o = filteredOptions(data, selectedOptions).map(d => {
      if (d.icon)
        return (
          <Option key={d.value} value={d.value}>
            <CustomIcon style={{ paddingRight: '5px !important' }} type={d.icon} /> {d.text.trim()}
          </Option>
        );

      return (
        <Option key={d.value} value={d.value}>
          {d.text.trim()}
        </Option>
      );
    });
    setOptions(o);
  };

  const setCurrentValue = (data: SearchInputData[]): void => {
    if (value) {
      const currentData = data.filter(d => d.value === value);
      if (currentData.length > 0) {
        setLocalValue(value);
      }
    }
  };

  async function loadDefaultOptions(): Promise<void> {
    setOptions([]);
    setFetching(true);

    const data = await fetch();

    // This validation is here to check if the current selected value is on any existing option of the data that will be display on the Dropdown.
    // If is not, for instance an old id of a parameter that was removed, or a new data triggered via `initOn` that will not be related to the current selected value
    // as an option, in that case we asume that the value is undefined and then clear the Dropdown, or if we have a partial hit, we keep that partial data as
    // the selected value of the Dropdown, and call the handleChange event that will trigger all the functionallity
    if (value && data.length === 0) {
      if ((Array.isArray(value) && value.length > 0) || !Array.isArray(value)) {
        handleChange(undefined);
      }
    } else if (value && data.length > 0) {
      if (Array.isArray(value) && value?.length > 0) {
        const intersectData = data
          .filter(v => value.includes(v.value))
          .map(x => x.value)
          .sort();

        if (intersectData.length > 0 && !isEqual(intersectData, value.sort())) {
          handleChange(intersectData);
        } else if (intersectData.length === 0) {
          handleChange(undefined);
        }
      } else if (!Array.isArray(value) && !data.some(x => x.value === value)) {
        handleChange(undefined);
      }
    }

    setDefaultData(data);
    optionsBuilder(data, selectedOptions);
    setCurrentValue(data);
    setFetching(false);
  }

  useEffect(() => {
    if (!initOn) {
      loadDefaultOptions();
    }
  }, []);

  const handleSearch = async (filter: string): Promise<void> => {
    if (!filter) {
      optionsBuilder(defaultData, selectedOptions);
      return;
    }
    setFetching(true);

    const data = await fetch(filter);

    optionsBuilder(data, selectedOptions);
    setFetching(false);
  };

  const onSearch = debounce(handleSearch, 800);

  const handleChange = (v: number | string | Array<string | number> | undefined): void => {
    setLocalValue(v);

    onChange && onChange(v);
  };

  const onClear = (): void => {
    handleChange(undefined);
    optionsBuilder(defaultData, selectedOptions);
  };

  useEffect(() => {
    if (!initOn && defaultData.length > 0) {
      handleChange(undefined);
      setOptions([]);
      return;
    }
    //if (initOn) {
    loadDefaultOptions();
    //}
  }, [initOn]);

  const showSearchValue = (): boolean => {
    if (showSearch === false) return false;

    return true;
  };

  const getSelectWithSearch = (): ReactElement | null => {
    if (!options || options.length === 0) {
      return (
        <SelectWithSearch
          ref={ref}
          {...props}
          value={undefined}
          mode={mode}
          placeholder={placeholder || t('select')}
          onClick={e => e.stopPropagation()}
          disabled={disabled || (disableOnEmpty && options?.length === 0)}
          notFoundContent={
            fetching ? (
              <>
                <ClipLoader size={10} color='#56CCF2' /> {t('loading')}
              </>
            ) : (
              t('noDataMessage')
            )
          }
        />
      );
    }

    return (
      <SelectWithSearch
        ref={ref}
        placeholder={placeholder || t('select')}
        {...props}
        onClick={e => e.stopPropagation()}
        disabled={disabled || (disableOnEmpty && options?.length === 0)}
        mode={mode}
      >
        {options}
      </SelectWithSearch>
    );
  };

  return (
    <>
      {remoteSearch ? (
        <Select
          ref={ref as any}
          showSearch={showSearchValue()}
          value={localValue}
          defaultActiveFirstOption={false}
          showArrow
          filterOption={false}
          mode={mode}
          disabled={disabled || (disableOnEmpty && options?.length === 0)}
          onSearch={onSearch}
          onClear={onClear}
          onChange={handleChange}
          placeholder={placeholder || t('select')}
          notFoundContent={
            fetching ? (
              <>
                <ClipLoader size={10} color='#56CCF2' /> {t('loading')}
              </>
            ) : (
              t('noDataMessage')
            )
          }
          {...rest}
          onClick={e => e.stopPropagation()}
        >
          {options}
        </Select>
      ) : (
        getSelectWithSearch()
      )}
    </>
  );
});
