import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, DatePicker, Form, Input, message, Modal, Row, Steps } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { IconButton, SearchInput } from '../Common';
import { useCompaniesInfo } from '../../hooks/UseCompaniesInfo';
import { useUsersInfo } from '../../hooks/UseUsersInfo';
import { useErrorMessageHandler, useSharedContext } from '../../hooks';
import { useGroupsAPIClient } from '../../hooks/api/GroupsAPIClient';
import JobOffersListFormItem from './JobOffersListFormItem';
import { isEmpty } from 'lodash';
import { SecondaryButton } from '../Common/IconButton';

import './css/Add.css';

type AddProps = {
  onGroupAdded?: () => void;
  showOverlay?: () => void;
  hideOverlay?: () => void;
  onCancel: () => void;
};

export const Add: React.FC<AddProps> = ({ onGroupAdded, showOverlay, hideOverlay, onCancel }) => {
  const [companyIds, setCompanyIds] = useState<Array<number>>([]);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [stepsData, setStepsData] = useState<any>();
  const [form] = Form.useForm();

  const { t } = useTranslation();
  const { getCompaniesInputData } = useCompaniesInfo();
  const { getUserSearchInputList } = useUsersInfo();
  const { formHasChanges, setFormHasChanges } = useSharedContext();
  const { errorMessageHandler } = useErrorMessageHandler();

  const { confirm } = Modal;
  const { TextArea } = Input;
  const { Step } = Steps;

  const groupsAPIClient = useGroupsAPIClient();

  const handleFormValuesChanges = (): void => {
    setFormHasChanges(true);
  };

  const onSubmit = async (formData): Promise<void> => {
    setFormHasChanges(false);
    showOverlay && showOverlay();

    const res = await groupsAPIClient.create(formData);

    if (res.hasError) {
      errorMessageHandler(res);
      setFormHasChanges(true);
      hideOverlay && hideOverlay();
      return;
    }

    message.success(`${t('group')} ${t('added')}!`);
    form.resetFields();
    hideOverlay && hideOverlay();

    onGroupAdded && onGroupAdded();
  };

  const onStep1Submit = step1Data => {
    setCurrentStep(1);
    setStepsData(step1Data);
  };

  const onStep2Submit = step2Data => {
    setStepsData({ ...stepsData, ...step2Data });
    setCurrentStep(2);
  };

  const onStep3Submit = step3Data => {
    const formsData = { ...stepsData, ...step3Data };
    onSubmit(formsData);
  };

  const onConfirmCancel = () => {
    form.resetFields();
    setFormHasChanges(false);
    onCancel && onCancel();
  };

  const onCancelChanges = (): void => {
    confirm({
      title: t('confirmCancel'),
      content: t('confirmCancelMessage'),
      icon: <ExclamationCircleOutlined />,
      centered: true,
      onOk() {
        onConfirmCancel();
      },
      okText: t('yes'),
      cancelText: t('no'),
    });
  };

  const selectCompanyId = id => {
    const updated = [...companyIds, id];
    setCompanyIds(updated);
  };

  const deselectCompanyId = id => {
    const updated = companyIds.filter(x => x !== id);
    setCompanyIds(updated);
  };

  const getGroupMembers = async () => {
    const res = await getUserSearchInputList();
    return res.filter(item => stepsData.memberUserIds.includes(item.value));
  };

  return (
    <div className='component-body' id='Add'>
      <Steps type='navigation' current={currentStep} className='steps-container'>
        <Step status={currentStep === 0 ? 'process' : 'finish'} title={t('creation').toUpperCase()} />
        <Step status={currentStep === 0 ? 'wait' : 'process'} title={t('assigment').toUpperCase()} />
        <Step status={currentStep === 0 ? 'wait' : 'process'} title={t('referent').toUpperCase()} />
      </Steps>
      {currentStep === 0 && (
        <Form
          form={form}
          scrollToFirstError
          layout='vertical'
          autoComplete='off'
          onFinish={onStep1Submit}
          onValuesChange={() => {
            handleFormValuesChanges();
          }}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={24}>
              <Form.Item
                label={t('groupName')}
                name='name'
                rules={[{ required: true, message: t('pleaseInputAGroupName') }]}
              >
                <Input placeholder={`${t('assignGroupName')}`} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('startDate')}
                name='beginDate'
                rules={[{ required: true, message: `${t('pleaseInputADate')}!` }]}
              >
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t('endDate')} name='endDate'>
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t('companies')}
                name='companyIds'
                rules={[{ required: true, message: t('pleaseInputACompanyName') }]}
              >
                <SearchInput
                  placeholder={t('searchCompany')}
                  fetch={getCompaniesInputData}
                  mode='multiple'
                  onClear={() => setCompanyIds([])}
                  onSelect={id => selectCompanyId(id)}
                  onDeselect={id => deselectCompanyId(id)}
                />
              </Form.Item>
            </Col>
            {!isEmpty(companyIds) && (
              <Col span={24}>
                <Form.Item
                  name='jobOfferIds'
                  label={t('jobOffers')}
                  rules={[{ required: !isEmpty(companyIds), message: `${t('pleaseSelect')} ${t('jobOffers')}!` }]}
                >
                  {companyIds.length > 0 && <JobOffersListFormItem companyIds={companyIds} />}
                </Form.Item>
              </Col>
            )}
            <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div>
                <IconButton htmlType='submit'>{t('next')}</IconButton>
                <SecondaryButton disabled={!formHasChanges} onClick={onCancelChanges}>
                  {t('cancel')}
                </SecondaryButton>
              </div>
            </Col>
          </Row>
        </Form>
      )}
      {currentStep === 1 && (
        <Form
          form={form}
          scrollToFirstError
          layout='vertical'
          onFinish={onStep2Submit}
          onValuesChange={() => {
            handleFormValuesChanges();
          }}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={24}>
              <Form.Item
                label={t('members')}
                name='memberUserIds'
                rules={[{ required: true, message: `${t('pleaseSelect')} ${t('members')}!` }]}
              >
                <SearchInput fetch={getUserSearchInputList} mode='multiple' />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={t('objective')} name='objective'>
                <TextArea style={{ width: '100%' }} maxLength={750} rows={4} />
              </Form.Item>
            </Col>
            <Col span={24} className='buttons-container'>
              <div>
                <span className='back-button' onClick={() => setCurrentStep(0)}>
                  {t('previous')}
                </span>
              </div>
              <div>
                <IconButton htmlType='submit'>{t('next')}</IconButton>
                <SecondaryButton disabled={!formHasChanges} onClick={onCancelChanges}>
                  {t('cancel')}
                </SecondaryButton>
              </div>
            </Col>
          </Row>
        </Form>
      )}
      {currentStep === 2 && (
        <Form
          form={form}
          scrollToFirstError
          layout='vertical'
          onFinish={onStep3Submit}
          onValuesChange={() => {
            handleFormValuesChanges();
          }}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={24}>
              <Form.Item
                label={t('referent')}
                name='referentUserId'
                rules={[{ required: true, message: `${t('pleaseSelect')} ${t('referent')}!` }]}
              >
                <SearchInput fetch={getGroupMembers} />
              </Form.Item>
            </Col>
            <Col span={24} className='buttons-container'>
              <span className='back-button' onClick={() => setCurrentStep(1)}>
                {t('previous')}
              </span>
              <div>
                <IconButton htmlType='submit'>{t('createGroup')}</IconButton>
                <SecondaryButton disabled={!formHasChanges} onClick={onCancelChanges}>
                  {t('cancel')}
                </SecondaryButton>
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </div>
  );
};

export default Add;
