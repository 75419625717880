import { JobOfferAnsweredQuestion, QuestionCategory } from '../JobOffers/types';

export const parseQuestionsFilter = formData => {
  const parsedQuestions: Array<JobOfferAnsweredQuestion> = [];

  for (let key in formData) {
    const questionId = parseInt(key.split('-')[1]);
    const answers = Array.isArray(formData[key])
      ? formData[key]
      : formData[key] !== undefined && formData[key] !== null
        ? [formData[key]]
        : [];

    if (questionId && answers.length > 0) {
      if (answers.length === 2) {
        const [rangeMin, rangeMax] = answers[0] < answers[1] ? answers : [answers[1], answers[0]];

        parsedQuestions.push({
          questionId: questionId,
          answers: answers,
          rangeMin: rangeMin,
          rangeMax: rangeMax,
        });
      } else {
        parsedQuestions.push({
          questionId: questionId,
          answers: answers,
        });
      }
    }
  }

  return parsedQuestions;
};

export const parseQuestionsInitialValues = (
  questions?: Array<JobOfferAnsweredQuestion>,
): { key: string; value: Array<number | string> } => {
  const initialValues: any = {};

  questions?.forEach(question => {
    if (question?.question?.category === QuestionCategory.MultipleChoice) {
      initialValues[`question-${question.questionId}`] = question.answers.map(answer =>
        typeof answer === 'string' ? parseInt(answer) : answer,
      );
      return;
    }
    if (
      question?.question?.category === QuestionCategory.SingleChoice ||
      question?.question?.category === QuestionCategory.Range
    ) {
      initialValues[`question-${question.questionId}`] =
        typeof question.answers[0] === 'string' ? parseInt(question.answers[0]) : question.answers[0];
      return;
    }
    if (question?.question?.category === QuestionCategory.Text) {
      initialValues[`question-${question.questionId}`] = question.answers[0];
      return;
    }

    // Default case
    initialValues[`question-${question.questionId}`] = question.answers.map(answer => answer);
  });
  return initialValues;
};

export default { parseQuestionsFilter, parseQuestionsInitialValues };
