import { TechnologyFilter } from '../Candidates/types';
import { Address, Currency, KeyValuePair, Industry, Language, PriorityLevel, Seniority } from '../Common/types';
import { EmailContactOption } from '../TenantSettings/EmailTemplates/types';

export enum Status {
  draft = 1,
  active,
  paused,
  completed,
  closed,
}

export enum Department {
  DevOps = 1,
  IT,
  softwareEngineering,
  cxo,
  finance,
  support,
  productProjectManagement,
  QA,
}

export enum RequirementPriority {
  required = 1,
  niceToHave,
}

export enum WorkMethodology {
  remote = 1,
  onSite,
  hybrid,
}

export type JobOfferListItem = {
  id: number;
  status: number;
  priorityLevel: number;
  priorityLevelName: string;
  statusName: string;
  subStatusId?: number;
  subStatusName?: string;
  technologies: Array<JobOfferTechnology>;
  companyExecutiveUserId?: string;
  companyExecutiveUserName?: string;
  companyExecutiveUserPictureUrl?: string;
  lastNote?: string;
  languages?: Array<Language>;
  seniority?: Seniority;
  mainRoleId?: number;
  companyName: string;
  companyId: number;
  salaryCurrency?: string;
  title: string;
  candidatesSourcedCount: number;
  candidatesActiveCount: number;
  candidatesReadyCount: number;
  candidatesHiredCount: number;
  jobApplicationsDraftCount: number;
  jobApplicationsHiredCount: number;
  jobApplicationsActiveCount: number;
  jobApplicationsPreSelectedCount: number;
  jobApplicationsCount: number;
  positionsCount: number;
  pinned?: boolean;
  countries?: Array<{ country: string; countryName: string }>;
  createdAt: string;
  salaryMin?: number;
  salaryMax?: number;
  importantComments?: string;
  isPublic: boolean;
  tenantVisible: boolean;
  restrictions?: string;
};

export type JobOfferTechnology = {
  id: number;
  technologyId: number;
  description: string;
  level: number;
  levelName: string;
  experienceYears: number;
  orTechnologyId?: number;
  priority: RequirementPriority;
  priorityName?: string;
};

export type JobOfferLanguage = {
  id: number;
  languageId: number;
  description: string;
  level: number;
  levelName: string;
};

export interface JobOfferQuestion {
  id: number;
  question: Question;
  questionId: number;
  required: boolean;
}

export type JobOfferAnsweredQuestion = {
  answers: Array<string>;
  questionId: number;
  question?: Question;
  rangeMin?: number;
  rangeMax?: number;
};

export type JobOffer = {
  id: number;
  addresses?: Address[];
  mainRoleName: string;
  mainRoleId?: number;
  description: string;
  fullDescription?: string;
  exerciseInstructions?: string;
  isPublic: boolean;
  industries?: Array<Industry>;
  tenantVisible: boolean;
  companyVisible: boolean;
  companyBenefits?: string;
  createdAt: string;
  createdByUserName: string;
  createdByUserId: string;
  updatedByUserId: string;
  updatedAt?: string;
  seniority?: Seniority;
  updatedByUserName?: string;
  companyId: number;
  companyLinkedInProfile?: string;
  companyName?: string;
  companyLogo?: string;
  companyWebSite?: string;
  companyRecruitmentNotes?: string;
  emailContactOption?: EmailContactOption;
  companyAbout?: string;
  title: string;
  recruitmentNotes?: string;
  priorityLevelName?: string;
  onlyEnglish?: boolean;
  status: number;
  statusName?: string;
  technologiesCount: number;
  jobApplicationsCount: number;
  activitiesCount: number;
  scheduledTasksCount: number;
  subStatusId?: number;
  subStatusName?: string;
  assignationReason?: string;
  otherSkillsAndTechnologies?: string;
  technologies: Array<JobOfferTechnology>;
  languages: Array<JobOfferLanguage>;
  questions: Array<JobOfferQuestion>;
  workMethodologyName?: string;
  workMethodology?: WorkMethodology;
  timezone: string;
  yearsOfExperience: number;
  salaryMin: number;
  salaryMax: number;
  contractTypeName?: string;
  contractTermName?: string;
  shortDescription?: string;
  seniorityName?: string;
  departmentId?: number;
  departmentName?: string;
  salaryTypeName?: string;
  salaryCurrency?: Currency;
  hourSalary?: number;
  positionsCount?: number;
  requirements: Array<JobOfferRequirement>;
  thisCountryOnly: boolean;
  notes?: string;
  managementNotes?: string;
  importantComments?: string;
  document?: string;
  benefits?: string;
  selectionProcess?: string;
  selectionProcessSteps?: Array<SelectionProcessStep>;
  currentSelectionProcessStepId?: number;
  currentSelectionProcessStepName?: string;
  restrictions?: string;
  dataQualityValue: number;
  dataQualityMissingFeedbackNames: Array<string>;
  dataQualityDoneFeedbackNames: Array<string>;
};

export type JobOfferApplyDetails = {
  onlyEnglish: boolean;
  title: string;
  salaryCurrency?: Currency;
  questions: Array<JobOfferQuestion>;
};

export type SelectionProcessStep = {
  id: number;
  description?: string;
  name?: string;
  order: number;
};

export type JobOfferCreate = Omit<JobOffer, 'id'>;

export type JobOfferRequirement = {
  id: number;
  description: string;
  status?: Status;
  statusName?: string;
  priority: RequirementPriority;
  priorityName: string;
};

export type MatchingJobOffer = {
  id: number;
  companyLogo?: string;
  companyId: number;
  salaryMin?: number;
  companyName: string;
  title: string;
  priorityLevelName?: string;
  statusName: string;
  jobApplicationsDraftCount: number;
  jobApplicationsActiveCount: number;
  jobApplicationsHiredCount: number;
  jobApplicationsCount: number;
  scorePercentage?: number;
  group: string;
  status: number;
  feedback: Array<string>;
};

export type MatchesFilters = {
  status?: number;
  filter?: string;
  candidateSalaryMin?: number;
};

export type JobOfferActivity = {
  jobOfferId: number;
  title: string;
};

export type UnappliedJobOffer = {
  id: number;
  companyName: string;
  title: string;
  description: string;
};

export type JobApplicationsAndJobOffersTotalCount = {
  jobApplicationsCount: number;
  jobOffersCount: number;
};

export type AdvancedSearchFilters = {
  companyIds?: Array<number>;
  status?: Status;
  priorityLevel?: PriorityLevel;
  technologies?: Array<TechnologyFilter>;
  country?: string;
};

export enum QuestionCategory {
  Text = 1,
  SingleChoice,
  MultipleChoice,
  Range,
}

export interface Question {
  id: number;
  descriptionEs: string;
  type?: string;
  descriptionEn: string;
  description: string;
  category: QuestionCategory;
  source: string;
  options: Array<QuestionOption>;
}

export interface JobOfferStats {
  pastInterviewsCount: number;
  nextInterviewsCount: number;
  interviewsCount: number;
  jobApplicationsActiveCount: number;
  jobApplicationsDraftCount: number;
  jobApplicationsPreSelectedCount: number;
  jobApplicationsHiredCount: number;
  jobApplicationsPausedCount: number;
  jobApplicationsNotAGoodFitCount: number;
  jobApplicationsRejectedByTheCandidateCount: number;
  jobApplicationsRejectedByTheEmployerCount: number;
  jobApplicationsRejectedByTheCandidateSubStatusesCount: Array<KeyValuePair>;
  jobApplicationsRejectedByTheEmployerSubStatusesCount: Array<KeyValuePair>;
  jobApplicationsNotAGoodFitSubStatusesCount: Array<KeyValuePair>;
  jobApplicationsCount: number;
  presentedCandidatesStatus: Array<KeyValuePair>;
}

export interface  ChartData {
  interviews: Array<{ id: string; value: number; label: string }>;
  jobApplicationsStatus: Array<{ id: string; value: number; label: string }>;
  rejectedByTheEmployer: Array<{ id: string; value: number; label: string }>;
  rejectedByCandidate: Array<{ id: string; value: number; label: string }>;
  notAGoodFitSubStatusesCount: Array<{ id: string; value: number; label: string }>;
  presentedCandidates: Array<{ id: string; value: number; label: string }>;
};

export interface QuestionOption {
  id: number;
  descriptionEs: string;
  type?: string;
  descriptionEn: string;
  description: string;
}

export interface JobApplicationQuestionAnswers {
  questionId: number;
  answers: string[];
}
