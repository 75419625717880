import {
  Address,
  Assignation,
  ContractTerm,
  ContractType,
  Industry,
  SalaryType,
  Seniority,
  WorkMethodology,
} from '../Common/types';
import { Interview } from '../Interviews/types';
import { Dayjs } from 'dayjs';
import { TechnologyInterest } from '../Common/types';
import { JobApplication, MatchGroup } from '../JobApplications/types';

export enum DegreeLevel {
  elementarySchool = 1,
  highSchool,
  associateDegree,
  bachelorsDegree,
  mastersDegree,
  doctorate,
}

export enum DegreeStatus {
  inProgress = 1,
  completed,
}

export enum DoNotContactPeriod {
  forever = 1,
  sixMonths,
  twoYears,
  fiveYears,
}

export enum Status {
  pool = 1,
  sourced,
  active,
  ready,
  hired,
  onHold,
  doNotContact,
}

export enum LanguageLevel {
  a2Novice = 1,
  b1Intermediate,
  b2HighIntermediate,
  c1Advanced,
  c2Native,
}

export enum Gender {
  male = 1,
  female,
  other,
  ratherNotSay,
}

export enum EmploymentStatus {
  employed = 1,
  notEmployed,
  selfEmployed,
}

export enum CandidatesStatusType {
  pool = 'pool',
  sourced = 'sourced',
  active = 'active',
  ready = 'ready',
  hired = 'hired',
  onHold = 'onHold',
  all = 'all',
}

export enum ResumeReviewStatus {
  approved = 1,
  rejected,
}

export enum ResumeFiltersStatus {
  approved = 1,
  rejected,
  pending,
}

export interface CandidateListItem {
  id: number;
  country?: string;
  countryName?: string;
  status: number;
  statusName: string;
  lastStatusUpdatedAt;
  lastStatusNote;
  seniority?: number;
  seniorityName?: string;
  mainRoleName?: string;
  secondaryRoleName?: string;
  stageId?: number;
  stageName: string;
  fullName: string;
  flags: Array<CandidateFlag>;
  technologies: Array<CandidateTechnology>;
  lastNote?: string;
  pinned: boolean;
  email?: string;
  importantComments?: string;
  age: number;
  linkedInProfile?: string;
  hiredFrom?: string;
  hiredTo?: string;
  doNotContactPeriod?: DoNotContactPeriod;
  resumeFriendlyName: string;
  resumeExternalLink: string;
  resumeAnyRejected?: boolean;
  tagId: string;
  jobApplicationsCount: number;
}

export type CandidateFlag = {
  flagId: number;
  icon?: string;
  description?: string;
};

export type CandidateResume = {
  id: number;
  reviews: Array<ResumeReview>;
  url: string;
  friendlyName?: string;
  languageDescription?: string;
  languageId?: number;
  createdAt: string;
  createdByUserId: string;
  createdByUserName: string;
  updatedAt?: string;
};

export type CandidateSoftSkills = {
  effectiveCommunication?: number;
  commitment?: number;
  proactivity?: number;
  flexibility?: number;
  leadership?: number;
  resultsOriented?: number;
  multiCulturalManagement?: number;
};

export type CandidatesSimilar = {
  id: number;
  languages: Array<CandidateLanguage>;
  technologies: Array<CandidateTechnology>;
  status: Status;
  expectedSalaryMin: number;
  expectedSalaryMax: number;
  picture: string;
  mainRoleName: string;
  firstName: string;
  lastName: string;
  seniority: Seniority;
  seniorityName: string;
  statusName: string;
  score: number;
  country: string;
  countryName: string;
  scorePercentage: number;
  group: string;
  age: number;
  ownedByUserId: string;
  ownedByUserName: string;
  ownedByUserPictureUrl: string;
};

export type Candidate = {
  id: number;
  age: number;
  firstName: string;
  lastName: string;
  lastStatusUpdatedAt?: string;
  privateProfileId?: number;
  email?: string;
  dataQualityValue?: number;
  dataQualityMissingFeedbackNames: Array<string>;
  dataQualityDoneFeedbackNames: Array<string>;
  degreeLevelCondition?: number;
  degreeLevelConditionName?: string;
  linkedInProfile?: string;
  gitHubProfile?: string;
  behanceProfile?: string;
  portfolio?: string;
  resumeExternalLink?: string;
  resumeFriendlyName?: string;
  resume?: string;
  createdAt: string;
  createdByUserName: string;
  softSkills: CandidateSoftSkills;
  industries: Array<Industry>;
  updatedAt: string;
  updatedByUserId: string;
  updatedByUserName: string;
  mainRoleId?: number;
  mainRoleName?: string;
  secondaryRoleId?: number;
  secondaryRoleName?: string;
  seniority?: number;
  seniorityName?: string;
  picture?: string | null;
  about?: string;
  score?: number;
  phone?: string;
  statusName: string;
  status: number;
  stageName: string;
  stageId?: number;
  tags?: Array<string>;
  tagId: string;
  importantComments?: string;
  presentationNotes?: string;
  assignationReason?: string;
  comments?: string;
  address: Address;
  jobApplicationsCount: number;
  technologiesCount: number;
  technologies: Array<CandidateTechnology>;
  resumes: Array<CandidateResume>;
  languages: Array<CandidateLanguage>;
  languagesCount: number;
  flagsCount: number;
  managerFlagsCount: number;
  activitiesCount: number;
  versionsCount: number;
  yearsOfExperience?: number;
  pinned?: boolean;
  availableFrom?: string;
  expectedHourSalary?: number;
  expectedCompany: string;
  expectedJobTitle: string;
  expectedSalaryMin?: number;
  expectedSalaryMax?: number;
  expectedSalaryCurrency?: string;
  expectedContractTypeNames: Array<string>;
  expectedContractTermNames: Array<string>;
  expectedSalaryTypeNames: Array<string>;
  expectedWorkMethodologiesNames: Array<string>;
  currentContractTermName?: string;
  currentContractTypeName?: string;
  otherFiles: Array<string>;
  flags: Array<CandidateFlag>;
  managerFlags: Array<CandidateFlag>;
  facebookProfile?: string;
  instagramProfile?: string;
  twitterProfile?: string;
  genderName?: string;
  gender?: number;
  birthDate?: string;
  countryOfBirth?: string;
  documentIdNumber?: string;
  nationality?: string;
  highestDegreeLevelName?: string;
  highestDegreeTitle?: string;
  currentEmploymentStatusName?: string;
  currentCompany?: string;
  currentJobTitle?: string;
  currentSalary?: number;
  currentSalaryCurrency?: string;
  currentSalaryTypeName?: string;
  noticePeriod?: number;
  referredBy?: string;
  recommendedBy?: string;
  contactDate?: string;
  contactReason?: string;
  otherSkillsAndTechnologies?: string;
  relocationOnly?: boolean;
  haveVisa?: boolean;
  interviewsCount: number;
  assignations: Array<Assignation>;
  statusHistoryCount: number;
  ownersAndAssignationsHistoryCount: number;
  scheduledTasksCount: number;
  timezone?: string;
  doNotContactPeriod?: DoNotContactPeriod;
  hiredFrom?: string;
  hiredTo?: string;
  title?: string;
  ownedByUserId?: string;
  ownedByUserName?: string;
  ownedAt?: string;
  codeChallengesCount: number;
};

export type CandidateCreate = {
  firstName: string;
  lastName: string;
  email?: string;
  linkedInProfile?: string;
  gitHubProfile?: string;
  portfolio?: string;
  resumeExternalLink?: string;
  resumeFriendlyName?: string;
  resume?: string;
  mainRoleId?: number;
  secondaryRoleId?: number;
  seniority?: number;
  picture?: string;
  about?: string;
  score?: number;
  phone?: string;
  status?: number;
  stageId?: number;
  importantComments?: string;
  presentationNotes?: string;
  comments?: string;
  address?: Address;
  technologies?: Array<CandidateTechnology>;
  resumes?: Array<CandidateResume>;
  languages?: Array<CandidateLanguage>;
  yearsOfExperience?: number;
  pinned?: boolean;
  availableFrom?: string;
  expectedHourSalary?: number;
  expectedCompany?: string;
  expectedJobTitle?: string;
  expectedSalaryMin?: number;
  expectedSalaryMax?: number;
  expectedSalaryCurrency?: string;
  expectedContractTypes?: Array<ContractType>;
  expectedContractTerms?: Array<ContractTerm>;
  expectedSalaryTypes?: Array<SalaryType>;
  expectedWorkMethodologies?: Array<WorkMethodology>;
  currentContractTerm?: ContractTerm;
  currentContractType?: ContractType;
  otherFiles?: Array<string>;
  flags?: Array<CandidateFlag>;
  managerFlags?: Array<CandidateFlag>;
  facebookProfile?: string;
  instagramProfile?: string;
  twitterProfile?: string;
  genderName?: string;
  birthDate?: string;
  countryOfBirth?: string;
  documentIdNumber?: string;
  nationality?: string;
  highestDegreeLevel?: DegreeLevel;
  highestDegreeTitle?: string;
  currentEmploymentStatus?: EmploymentStatus;
  currentCompany?: string;
  currentJobTitle?: string;
  currentSalary?: number;
  currentSalaryCurrency?: string;
  currentSalaryTypeName?: string;
  noticePeriod?: number;
  referredBy?: string;
  recommendedBy?: string;
  contactDate?: string;
  contactReason?: string;
  otherSkillsAndTechnologies?: string;
  relocationOnly?: boolean;
  haveVisa?: boolean;
  timezone?: string;
  hiredFrom?: string;
  hiredTo?: string;
  title?: string;
};

export type CandidateUpdateLinkedInExtension = {
  address: Address;
  languages: Array<CandidateLanguage>;
  seniority: Seniority;
};

export interface CandidateVersion {
  id: number;
  technologies: Array<CandidateTechnology>;
  languages: Array<CandidateLanguage>;
  flags: Array<CandidateFlag>;
  firstName: string;
  lastName: string;
  email?: string;
  linkedInProfile?: string;
  gitHubProfile?: string;
  resumeExternalLink?: string;
  resume?: string;
  mainRole?: string;
  secondaryRole?: string;
  seniority?: string;
  picture?: string;
  score?: number;
  phone?: string;
  status: string;
  stageId?: string;
  importantComments?: string;
  presentationNotes?: string;
  comments?: string;
  address: Address;
  yearsOfExperience?: number;
  availableFrom?: string;
  expectedHourSalary?: number;
  expectedCompany: string;
  expectedJobTitle: string;
  expectedSalaryMin?: number;
  expectedSalaryMax?: number;
  expectedSalaryCurrency?: string;
  expectedContractTypes: Array<string>;
  expectedContractTerms: Array<string>;
  expectedSalaryTypes: Array<string>;
  expectedWorkMethodologies: Array<string>;
  currentContractTerm?: string;
  currentContractType?: string;
  otherFiles: Array<string>;
  facebookProfile?: string;
  instagramProfile?: string;
  twitterProfile?: string;
  gender?: string;
  birthDate?: string;
  countryOfBirth?: string;
  documentIdNumber?: string;
  nationality?: string;
  highestDegreeLevel?: string;
  highestDegreeTitle?: string;
  currentEmploymentStatus?: string;
  currentCompany?: string;
  currentJobTitle?: string;
  currentSalary?: number;
  currentSalaryCurrency?: string;
  currentSalaryType?: string;
  noticePeriod?: number;
  referredBy?: string;
  recommendedBy?: string;
  contactDate?: string;
  contactReason?: string;
  otherSkillsAndTechnologies?: string;
  relocationOnly?: boolean;
  haveVisa?: boolean;
  timezone: string;
  hiredFrom?: string;
  hiredTo?: string;
}

export interface MatchingCandidate {
  id: number;
  firstName: string;
  lastName: string;
  mainRoleName: string;
  secondaryRoleName?: string;
  seniorityRoleName?: string;
  picture?: string;
  linkedInProfile?: string;
  score?: number;
  expectedSalaryMin?: number;
  expectedSalaryMax?: number;
  technologies: Array<CandidateTechnology>;
  statusName: string;
  stageName: string;
  jobApplicationsDraftCount: number;
  jobApplicationsActiveCount: number;
  jobApplicationsHiredCount: number;
  jobApplicationsCount: number;
  candidatePrivateProfileId?: number;
  importantComments?: string;
  flags: Array<CandidateFlag>;
  lastInterview: string;
  finishedInterviewsCount: number;
  age: number;
  country?: string;
  countryName?: string;
  isTalentNetwork?: boolean;
  candidateId?: number;
  latestFinishedInterviewDate?: string;
  scorePercentage?: number;
  group: string;
  email: string;
  feedback: Array<string>;
}

export type CandidateTechnology = {
  id: number;
  technologyId: number;
  description: string;
  level: number;
  interest: TechnologyInterest;
  interestName: string;
  validationDate: string;
  validationUserId: string;
  validationUserName: string;
  levelName: string;
  experienceYears: number;
};

export type CandidateLanguage = {
  id: number;
  languageId: number;
  description: string;
  descriptionEs?: string;
  descriptionEn?: string;
  level?: number;
  levelName?: string;
};

export type CandidateActivity = {
  candidateId: number;
  fullName: string;
};

export type UnappliedCandidate = {
  id: number;
  firstName: string;
  lastName: string;
  linkedInProfile?: string;
};

export enum Referrals {
  UADE = 'UADE',
  'Le Wagon' = 'LEWAGON',
  Coderhouse = 'CODERHOUSE',
  emtech = 'EMTECH',
}

export type CandidateByLinkedInProfile = Candidate & {
  id: number;
  contact: boolean;
  linkedInProfile: string;
  isExternalRecruiter: boolean;
  firstName?: string;
  lastName?: string;
  picture?: string | null;
  countryName?: string;
  countryCode?: string;
  status: Status;
  statusName: string;
  seniority?: Seniority;
  seniorityName?: string;
  lastApplicationDate?: string;
  lastStatusNote?: string;
  lastStatusUpdatedAt?: string;
  lastActivityNote?: string;
  lastActivityNoteCreatedByUserId?: string;
  lastActivityNoteCreatedByUserName?: string;
  lastActivityNoteCreatedAt?: string;
  lastActivityContact?: string;
  lastActivityContactCreatedByUserId?: string;
  lastActivityContactCreatedByUserName?: string;
  lastActivityContactCreatedAt?: string;
  jobApplications?: Array<JobApplication>;
  isMyCandidate: boolean;
  isAssignedToMe?: boolean;
  currentCompany?: string;
  createdAt?: string;
  updatedAt?: string;
  createdByUserId?: string;
  createdByUserName?: string;
  updatedByUserId?: string;
  updatedByUserName?: string;
  ownedAt?: string;
  ownedByUserId?: string;
  ownedByUserName?: string;
  importantComments?: string;
  tags?: Array<string>;
  tagId: string;
  age?: number;
  interviews: Array<Interview>;
};

export type StatusChangeData = {
  candidateId: number;
  currentStatus: number;
  currentStage?: number;
  currentHiredFrom?: string;
  currentHiredTo?: string;
  currentDoNotContactPeriod?: DoNotContactPeriod;
};

export type CustomAccessToken = {
  customAccessToken: string;
};

export type CandidateExternalEdit = {
  firstName: string;
  lastName: string;
  email?: string;
  linkedInProfile?: string;
  technologies: Array<CandidateTechnology>;
  languages: Array<CandidateLanguage>;
};

export type TechnologyFilter = {
  technologyId?: number;
  level?: number;
  experienceYears?: number;
  orTechnologyId?: number;
  baseOrTechnology?: boolean;
};

export type IndustryFilter = {
  description?: string;
  industryId?: number;
  experienceYears?: number;
};

export type LanguageFilter = {
  languageId?: number;
  level?: number;
};

export type AdvancedSearchFilters = {
  firstName?: string;
  lastName?: string;
  gender?: Gender;
  mainRolesIds?: Array<number>;
  seniorities?: Array<number>;
  technologies?: Array<TechnologyFilter>;
  ageRange?: string;
  interviewFinished?: boolean;
  createdAtFrom?: string;
  createdAtTo?: string;
  statuses?: Array<Status>;
  updatedAtFrom?: string;
  updatedAtTo?: string;
  country?: string;
  countries?: Array<string>;
  groupId?: number;
  assignedUserIds?: Array<string>;
  tags?: Array<string>;
  tagId?: string;
  languages?: Array<LanguageFilter>;
  industries?: Array<IndustryFilter>;
  expectedSalary?: number;
  expectedSalaryMin?: number;
  expectedSalaryMax?: number;
  expectedSalaryCurrency?: string;
  createdByUserId?: string;
  updatedByUserId?: string;
  createdAtDateRange?: Array<string | null>;
  updatedAtDateRange?: Array<string | null>;
  excludeOrFilter?: boolean;
  ownedByUserIds?: Array<string>;
  flags?: Array<number>;
  referredBy?: Referrals;
  managerFlags?: Array<number>;
  ageFrom?: string;
  ageTo?: string;
  withResume: boolean | null;
  resumeReviewStatus: number | null;
  includedJobOfferIds: Array<number>;
  excludedJobOfferIds: Array<number>;
};

export type AssignationAdvancedOptions = {
  removePreviousAssignations: boolean;
  giveOwnership: boolean;
};

export type NewAssignation = {
  assignedUserId: string;
  reason?: string;
  notes?: string;
  removePreviousAssignations: boolean;
  giveOwnership: boolean;
};

export type LocationState = {
  advancedFilters?: string;
  currentStatus?: string;
  locationSearch: string;
};

export type ResumeReview = {
  id: number;
  candidateResumeId: number;
  status: ResumeReviewStatus;
  createdAt: string;
  createdByUserId: string;
  createdByUserName: string;
  notes?: string;
  matchesCandidate: boolean;
  matchesEstablishedLanguage: boolean;
  isPDFFormat: boolean;
  hasPreviousWorkExperiences: boolean;
  responsibilitiesDetailed: boolean;
  includesBriefDescription: boolean;
  allInformationIsValid: boolean;
  hasValuableContent: boolean;
  technologiesDetailedInExperience: boolean;
};

export type ResumeReviewPayload = {
  notes?: string;
  matchesCandidate: boolean;
  matchesEstablishedLanguage: boolean;
  isPDFFormat: boolean;
  hasPreviousWorkExperiences: boolean;
  responsibilitiesDetailed: boolean;
  includesBriefDescription: boolean;
  allInformationIsValid: boolean;
  hasValuableContent: boolean;
  technologiesDetailedInExperience: boolean;
};

export type CandidateMatchesFilters = {
  statuses?: Array<Status>;
  filter?: string;
  candidateOwnerUserId?: string;
  jobOfferSalaryMin?: number;
  jobOfferSalaryMax?: number;
  countries?: Array<string>;
  languages?: Array<LanguageFilter>;
  finishedInterviewFrom?: Dayjs;
  finishedInterviewTo?: Dayjs;
  scoreMin?: number;
  matchGroups?: Array<MatchGroup>;
};

export type CodeChallengeTechology = {
  id: number;
  technologyId: number;
  description: string;
};

export type CodeChallengeStatus = {
  key: number;
  value: string;
};

export type CodeChallengeCreate = {
  title: string;
  status: number;
  difficulty: number;
  result: number;
  resultLink: string;
  candidateId: number;
  jobOfferId: number;
  technologies: CodeChallengeTechology[];
  comments: string;
};

export type CodeChallengeUpdate = CodeChallengeCreate & {
  id: number;
};

export type CodeChallenge = CodeChallengeUpdate & {
  statusName: string;
  jobOfferTitle: string;
  createdAt: string;
  updatedAt: string;
  createdByUserId: number;
  createdByUserName: string;
  updatedByUserId: number;
  updatedByUserName: string;
};

export type MatchingCandidatePrivateProfile = {
  id: number;
  technologies: CandidateTechnology[];
  expectedSalaryMin?: number;
  picture: string;
  mainRoleName: string;
  firstName: string;
  lastName: string;
  linkedInProfile: string;
  email: string;
  country: string;
  countryName: string;
  scorePercentage: number;
  englishLevel?: LanguageLevel;
  resumeUrl: string;
  group: string;
  order: number;
};

export type RejectCandidateData = {
  candidateId: number;
  candidateFullName: string;
};
