const hexToRgb = color => {
  color = color?.replace(/^#/, '');

  if (/^rgb/i.test(color)) {
    return color;
  }

  if (color?.length === 3) {
    color = color[0] + color[0] + color[1] + color[1] + color[2] + color[2];
  }

  const r = parseInt(color?.substring(0, 2), 16);
  const g = parseInt(color?.substring(2, 4), 16);
  const b = parseInt(color?.substring(4, 6), 16);

  return `rgba(${r},${g},${b}, 0.3)`;
};

export default hexToRgb;
