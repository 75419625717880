import React, { useState, useEffect } from 'react';
import { Button, Card, Dropdown, Menu, Avatar, Drawer } from 'antd';
import { UserOutlined, SafetyOutlined, SwapOutlined, SolutionOutlined, DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '../../hooks/auth/Auth0Provider';
import { TenantIcon } from '../Common';
import './css/UserProfile.css';
import { Roles } from '../../hooks/auth/types';

type UserProfileProps = {
  hideLogout?: boolean;
  hideInstallPlugin?: boolean;
  onTenantSwitched?: () => void;
};

export const UserProfile: React.FC<UserProfileProps> = props => {
  const { hideLogout, hideInstallPlugin, onTenantSwitched } = props;
  const { userIsInRole } = useAuth0();

  const {
    loading,
    user,
    logout,
    getUserRoles,
    getUserTenantsInfo,
    getCurrentUserTenant,
    setCurrentUserTenant,
    userIsAllowed,
  } = useAuth0();
  const { t } = useTranslation();
  const [currentTenant, setCurrentTenant] = useState<string | null>(null);
  const [userTenants, setUserTenants] = useState<Array<{ customer: string; tenant: string; plan: string }>>([]);
  const [tenantsVisible, setTenantsVisible] = useState<boolean>(false);
  useEffect(() => {
    const ct = getCurrentUserTenant();

    if (ct) {
      setCurrentTenant(ct);
    }

    const userTenantsInfo = getUserTenantsInfo();

    if (userTenantsInfo) {
      setUserTenants(userTenantsInfo);
    }
  }, []);

  const showTenants = (): void => {
    setTenantsVisible(true);
  };

  const closeTenants = (): void => {
    setTenantsVisible(false);
  };

  const onTenantSwitch = (tenant: string): void => {
    setCurrentUserTenant(tenant);
    setTenantsVisible(false);
    window.sessionStorage.clear();

    if (onTenantSwitched) {
      onTenantSwitched();
    } else {
      window.location.href = '/';
    }
  };

  const onProfileVisibleChange = (): void => {
    setTenantsVisible(false);
  };

  const menu = (
    <Menu className='menu'>
      <div className='dropdown-container'>
        <div id='UserProfile' className='container tenant-drawer-container'>
          {!hideLogout && (
            <div className='logo-signout'>
              <div className='logo'>
                <TenantIcon />
              </div>
              <Button type='link' className='signout' onClick={() => logout()}>
                {t('signout')}
              </Button>
            </div>
          )}
          <div className='user-data'>
            <Avatar
              className='avatar'
              icon={<UserOutlined />}
              src={<img src={user.picture} referrerPolicy='no-referrer' />}
            />

            <div className='data'>
              <div className='name'>
                <span>{user.name}</span>
              </div>
              <div className='email'>
                <span>{user.email}</span>
              </div>
              {currentTenant && userTenants && !userIsInRole(Roles.talentNetworkCandidate) && (
                <div className='tenant'>
                  <span>{userTenants.find(x => x.tenant === currentTenant)?.customer}</span>
                </div>
              )}
              {!userIsInRole(Roles.talentNetworkCandidate) && (
                <div className='email'>
                  <span>{userTenants.find(x => x.tenant === currentTenant)?.plan}</span>
                </div>
              )}
              {userIsAllowed() && !userIsInRole(Roles.talentNetworkCandidate) && (
                <>
                  <div className='switch-container'>
                    {currentTenant && userTenants?.length > 1 && (
                      <>
                        <Button type='link' className='switch-tenant' onClick={() => showTenants()}>
                          <SwapOutlined /> {t('switchTenant')}
                        </Button>

                        <Drawer
                          title='Switch tenant'
                          placement='right'
                          closable={false}
                          onClose={closeTenants}
                          visible={tenantsVisible}
                          getContainer='.tenant-drawer-container'
                          style={{ position: 'absolute', overflowX: 'hidden' }}
                        >
                          <ul key={currentTenant}>
                            {userTenants
                              .filter(t => t.tenant !== currentTenant)
                              .map(t => (
                                <li key={t.tenant}>
                                  <Button
                                    type='link'
                                    className='switch-tenant'
                                    onClick={() => onTenantSwitch(t.tenant)}
                                  >
                                    <div style={{ marginLeft: '10px' }}>
                                      <SolutionOutlined /> {t.customer}
                                    </div>
                                  </Button>
                                </li>
                              ))}
                          </ul>
                        </Drawer>
                      </>
                    )}
                  </div>
                  {!hideInstallPlugin && !userIsInRole(Roles.talentNetworkCandidate) && (
                    <div id='getPluginInstalled'>
                      <a
                        href='https://chrome.google.com/webstore/detail/webstartedhr-recruiter-as/pddhhnmijlmffonnhdpdilegfipcncln?hl=es&authuser=0'
                        target='_blank'
                        rel='noreferrer'
                        className='install-plugin-link'
                      >
                        <DownloadOutlined /> {t('installPlugin')}
                      </a>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          {userIsAllowed() && (
            <div>
              <div className='user-credentials'>
                <Card size='small' bordered={false}>
                  <ul>
                    {getUserRoles().map(rol => (
                      <li key={rol}>
                        <SafetyOutlined /> {rol}
                      </li>
                    ))}
                  </ul>
                </Card>
              </div>
            </div>
          )}
        </div>
      </div>
    </Menu>
  );

  if (loading || !user) {
    return <div>Loading...</div>;
  }

  return (
    <div id='UserAvatar'>
      <Dropdown overlay={menu} trigger={['click']} className='dropdown' onVisibleChange={onProfileVisibleChange}>
        <a onClick={e => e.preventDefault()} href='#self'>
          <Avatar
            className='user-avatar'
            src={user.picture}
            icon={<UserOutlined />}
            style={{ margin: '5px' }}
            size='large'
          />
        </a>
      </Dropdown>
    </div>
  );
};

export default UserProfile;
